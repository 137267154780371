import React, { FC, useState } from 'react';
// @ts-ignore
import styled, { ThemeProvider, useTheme } from '@xstyled/styled-components';
import { motion } from 'framer-motion';
import Check from './assets/check.svg';

const Container = styled.div`
  width: auto;
  height: auto;
`;

const Button = styled(motion.div)`
  width: 16px;
  height: 16px;
  border: 1px solid ${(props: any) => (props.borderColor ? props.borderColor : props.theme.colors.primary)};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: ${(props: any) => (props.editInProcess ? 'auto' : 'pointer')};
`;

const InnerCircle = styled(motion.div)`
  width: auto;
  height: auto;
  display: flex;
  border-radius: ${(props: any) => props.theme.borderRadius.primary};
  box-shadow: none;
  justify-content: center;
  align-items: center;
  padding-top: 2px;
`;

const LabelContainer = styled(motion.div)`
  width: auto;
  height: auto;
  display: flex;
  color: ${(props: any) => (props.color ? props.color : props.theme.colors.black1)};
  font-family: 'Poppins';
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  margin: 0px 0px 0px 6px;
  cursor: pointer;
  user-select: none;
`;
export interface CheckBoxProps {
  toggle?: boolean;
  disabled?: boolean;
  label: string;
  handleToggle: (event: React.MouseEvent<HTMLButtonElement>) => void;

  backgroundColor?: string;
  backgroundColorHover?: string;
  color?: string;
  className?: string;
  editInProcess?: boolean;
}

export const CheckBox: FC<CheckBoxProps> = ({
  toggle,
  label,
  handleToggle,
  disabled = false,
  color,
  backgroundColor,
  backgroundColorHover,
  editInProcess,
  ...props
}) => {
  const theme = useTheme();
  const { primary, white } = theme.colors;
  const [disabledState] = useState(disabled);
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    handleToggle(e);
  };

  return (
    <ThemeProvider theme={theme} {...props}>
      <Container>
        <Button
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            disabled ? e.preventDefault() : handleClick(e);
          }}
          onTouchStart={(e: any) => {
            disabled ? e.preventDefault() : handleClick(e);
          }}
          animate={{
            backgroundColor: toggle
              ? backgroundColorHover
                ? backgroundColorHover
                : primary
              : backgroundColor
              ? backgroundColor
              : white,
            opacity: disabledState ? 0.3 : 1,
          }}
          transition={{ duration: 0.1, ease: 'linear' }}
          borderColor={backgroundColorHover}
          theme={theme}
          editInProcess={editInProcess}
        >
          <InnerCircle
            animate={{
              opacity: toggle ? 1 : 0,
            }}
            theme={theme}
          >
            <img src={Check} alt="c" />
          </InnerCircle>
        </Button>
        <LabelContainer
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            disabled ? e.preventDefault() : handleClick(e);
          }}
          animate={{
            opacity: disabledState ? 0.3 : 1,
          }}
          theme={theme}
          color={color}
        >
          {label}
        </LabelContainer>
      </Container>
    </ThemeProvider>
  );
};

function handleToggle() {
  console.log('Toggled');
}

CheckBox.defaultProps = {
  toggle: false,
  label: 'Label',
  disabled: false,
  handleToggle: handleToggle,
};
