import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { media } from '../media';
import { SearchImage } from './components/SearchImage';
import { CloseButton } from '../close-button';
import { CloseButtonRound } from '../close-button-round';
import { LocationButton, ModalSearch } from '../location-button';
import { SearchBarModal } from '../search-bar-modal';
import { Text, TextType } from '../text/Text';
import { ModalRow } from './components/ModalRow';
import { BadgesType } from '../../components/badges/Badges';

export enum SearchBarType {
  CLASSIC = 'CLASSIC',
  WITHLOCATION = 'WITHLOCATION',
  CLASSIC_LOCATION = 'CLASSIC_LOCATION',
}

const ExtraWrapper = styled.div`
  width: 580px;
  display: flex;
  align-items: center;
  margin: 0px 0px 0px 0px;
  ${media.tablet`
  width: 343px;
  flex-direction: column;
  `}
`;

const Wrapper = styled.div`
  height: 40px;
  display: flex;
  position: relative;
`;

const InputWrapper = styled.div`
  width: 536px;
  height: 100%;
  position: relative;
  ${media.tablet`
    width: 300px;
  `}
`;

const ContainerRows = styled.div`
  max-height: 230px;
  box-sizing: border-box;
  overflow-y: auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 18px;
  &::-webkit-scrollbar {
    width: 5px;
    background-color: gray4;
    opacity: 0.6;
    border-radius: ${(props: any) => props.theme.borderRadius.primary};
  }
  &::-webkit-scrollbar-thumb {
    background-color: gray3;
    border-radius: ${(props: any) => props.theme.borderRadius.primary};
  }
`;

const SearchInput = styled.input`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: white;
  border: 1px solid;
  border-color: gray3;
  border-radius: ${(props: any) =>
    props.type === SearchBarType.WITHLOCATION
      ? `${props.theme.borderRadius.primary} 0 0  ${props.theme.borderRadius.primary}`
      : `${props.theme.borderRadius.primary}`};
  padding: 8px 38px;
  outline: none;
  font-family: 'Poppins', sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: black;
  &::placeholder {
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: gray2;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 8px;
  left: 8px;
  & > svg > path {
    stroke: ${(props: any) => props.colorOfSearchImage};
  }
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
`;

const LocationButtonWrapper = styled.div`
  position: relative;
`;

const MobileResultWrapper = styled.div`
  width: 100%;
  display: none;
  ${media.tablet`
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    margin: 8px 0 0;
    padding: 0 13px;
  `}
`;

const CityWrapper = styled.div`
  height: 28px;
  display: flex;
  align-items: center;
`;

const TextWrapper = styled.div`
  display: flex;
  margin-left: 18px;
`;

export interface SearchBarProps {
  type: SearchBarType;
  countResultOfSearching: number;
  placeholder?: string;
  height?: string;
  className?: string;
  handleLocationChange: any;
  locations?: string[];
  setSelectedPos?: any;
  search?: string;
  disabled?: boolean;
  defaultValue?: string;
  languageStrings?: any;
}

export const SearchBar: FC<SearchBarProps> = ({
  type,
  countResultOfSearching,
  handleLocationChange,
  setSelectedPos,
  locations,
  placeholder,
  search,
  disabled,
  defaultValue,
  languageStrings,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [inputValue, setInputValue] = useState(defaultValue ? defaultValue : '');
  const [modalSearch, setModalSearch] = useState(false);

  const modalRef = useRef<any>(null);
  const inputRef = useRef<any>(null);

  const theme = useTheme();
  const { green2, gray2, gray1 } = theme.colors;

  const handleChange = (e: any, handler: any) => {
    // setModalSearch(true);
    handler(e.target.value);
  };

  const handleClick = () => {
    setModalSearch(true);
  };

  const onClickOutsideHandler = useCallback(
    (event: any) => {
      if (
        modalSearch &&
        !(
          (inputRef && inputRef.current && inputRef.current.contains(event.target)) ||
          (modalRef.current && modalRef.current.contains(event.target))
        )
      ) {
        setModalSearch(false);
      }
    },
    [modalSearch],
  );

  useEffect(() => {
    window.addEventListener('click', onClickOutsideHandler);
    return () => {
      window.removeEventListener('click', onClickOutsideHandler);
    };
  }, [onClickOutsideHandler]);

  const handleCityChange = (e: any) => {
    handleLocationChange(e);
  };

  const handleClassicLocationClick = useCallback(
    (location: any) => {
      setModalSearch(false);
      setSelectedPos && setSelectedPos(location);
    },
    [setSelectedPos],
  );
  // @TODO Alex edit this later for actual search

  return (
    <ExtraWrapper {...props}>
      <Wrapper>
        <InputWrapper>
          <ImageWrapper type={type} colorOfSearchImage={green2}>
            <SearchImage />
          </ImageWrapper>
          <SearchInput
            ref={inputRef}
            theme={theme}
            type={type}
            placeholder={
              (languageStrings && languageStrings.insightSearchPlaceholder) || placeholder
                ? languageStrings.insightSearchPlaceholder
                : 'What are you looking for?'
            }
            value={type === SearchBarType.CLASSIC_LOCATION ? search : inputValue}
            onChange={
              type === SearchBarType.CLASSIC_LOCATION
                ? handleCityChange
                : (e: any) => {
                    handleChange(e, setInputValue);
                  }
            }
            onClick={handleClick}
            disabled={disabled}
          />
          {!disabled && inputValue.length > 0 ? (
            <CloseButtonWrapper type={type}>
              <CloseButton
                onClick={() => {
                  setInputValue('');
                }}
                color={gray1}
                hoverColor={gray2}
              />
            </CloseButtonWrapper>
          ) : null}
          {(type === SearchBarType.CLASSIC_LOCATION ? search : inputValue) && modalSearch && (
            <ModalSearch theme={theme} ref={modalRef}>
              <ContainerRows theme={theme}>
                {type === SearchBarType.CLASSIC_LOCATION ? (
                  locations?.map((location_data: any) => (
                    <ModalRow
                      text={location_data.name}
                      textBadges={'LOCATION'}
                      type={BadgesType.PRIMARY}
                      onClick={() => handleClassicLocationClick(location_data)}
                    />
                  ))
                ) : (
                  <>
                    <ModalRow text={'Coca-Cola Original'} textBadges={'ASSET'} type={BadgesType.PRIMARY} />
                    <ModalRow text={'Coca-Cola Original'} textBadges={'ASSET'} type={BadgesType.PRIMARY} />
                    <ModalRow text={'Coca-Cola Original'} textBadges={'ASSET'} type={BadgesType.PRIMARY} />
                    <ModalRow text={'Coca-Cola Original'} textBadges={'ASSET'} type={BadgesType.PRIMARY} />
                    <ModalRow text={'Coca-Cola Original'} textBadges={'ASSET'} type={BadgesType.PRIMARY} />
                    <ModalRow text={'Coca-Cola Original'} textBadges={'ASSET'} type={BadgesType.PRIMARY} />
                    <ModalRow text={'Coca-Cola Original'} textBadges={'ASSET'} type={BadgesType.PRIMARY} />
                    <ModalRow text={'Coca-Cola Original'} textBadges={'ASSET'} type={BadgesType.PRIMARY} />
                    <ModalRow text={'Coca-Cola Original'} textBadges={'ASSET'} type={BadgesType.PRIMARY} />
                    <ModalRow text={'Coca-Cola Original'} textBadges={'ASSET'} type={BadgesType.PRIMARY} />
                    <ModalRow text={'Coca-Cola Original'} textBadges={'ASSET'} type={BadgesType.PRIMARY} />
                  </>
                )}
              </ContainerRows>
            </ModalSearch>
          )}
        </InputWrapper>
        {type === SearchBarType.WITHLOCATION ? (
          <>
            <LocationButtonWrapper>
              <LocationButton
                onOpen={() => {
                  if (window.innerWidth > 768) {
                    if (!isOpen) {
                      setIsOpen(true);
                    }
                  } else {
                    setIsOpenModal(true);
                  }
                }}
                onClose={() => {
                  setIsOpen(false);
                }}
                isOpen={isOpen}
                location={search}
                locations={locations || []}
                handleLocationChange={handleCityChange}
                count={countResultOfSearching}
                setSelectedPos={setSelectedPos}
              />
            </LocationButtonWrapper>
          </>
        ) : null}
      </Wrapper>

      {type === SearchBarType.WITHLOCATION ? (
        <MobileResultWrapper>
          <CityWrapper>
            <Text type={TextType.TEXT_14_WHITE} color={green2}>
              in {search}
            </Text>
            <CloseButtonRound
              onClick={() => {
                console.log('Something');
              }}
            />
          </CityWrapper>
          <TextWrapper>
            <Text type={TextType.TEXT_14_GRAY} color={gray2}>
              {countResultOfSearching === 1
                ? `${countResultOfSearching} ${
                    languageStrings && languageStrings.insightSearchResultsResult
                      ? languageStrings.insightSearchResultsResult
                      : 'result'
                  } `
                : countResultOfSearching > 1
                ? `${countResultOfSearching} ${
                    languageStrings && languageStrings.insightSearchResultsResults
                      ? languageStrings.insightSearchResultsResults
                      : 'results'
                  }`
                : countResultOfSearching <= 0
                ? languageStrings && languageStrings.insightSearchResultsNoResults
                  ? languageStrings.insightSearchResultsNoResults
                  : 'no results'
                : null}
            </Text>
          </TextWrapper>
        </MobileResultWrapper>
      ) : null}

      <SearchBarModal
        location={search}
        locations={locations || []}
        handleLocationChange={handleCityChange}
        setSelectedPos={setSelectedPos}
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
      />
    </ExtraWrapper>
  );
};

SearchBar.defaultProps = {
  countResultOfSearching: 2,
};
