/**
 * Title for the Rechart library graph
 */

import React, { ReactNode } from 'react';
import styled from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 16px 20px 44px;
`;

const Title = styled.div`
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: ${colorFetch('gray1_5')};
`;

const ParametersBlock = styled.div`
  display: flex;
  flex-direction: row;
`;

type ParameterProps = {
  fontWeight: number;
};

const Parameter = styled.div`
  font-family: 'Poppins';
  font-weight: ${({ fontWeight }: ParameterProps) => fontWeight};
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: ${colorFetch('gray1_5')};
`;

export const GraphTitle = ({
  title,
  parameterName,
  parameterValue,
  isSelects,
  theme,
}: {
  title: string | ReactNode;
  parameterName?: string | ReactNode;
  parameterValue?: number | string;
  isSelects?: boolean;
  theme: any;
}) => {
  const withParametersBlock = parameterName && (typeof parameterValue === 'number' || 'string');

  return (
    <Wrapper>
      {/* @ts-ignore */}
      <Title>{title}</Title>
      {withParametersBlock && (
        <ParametersBlock>
          {/* @ts-ignore */}
          <Parameter fontWeight={400}>{isSelects ? parameterName : `${parameterName}:\xa0`}</Parameter>
          <Parameter fontWeight={500}>{parameterValue}</Parameter>
        </ParametersBlock>
      )}
    </Wrapper>
  );
};
