import React, { FC, ReactNode } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';

const Wrapper = styled.div`
  width: ${(props: any) => props.width};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: ${(props: any) => (props.backgroundColor ? props.backgroundColor : props.theme.colors.white)};
  box-shadow: ${(props: any) => props.theme.shadows.additionalShadow1};
  border-radius: ${(props: any) => props.theme.borderRadius.additional16};
  position: relative;
  padding: 24px 14px;
`;

export interface ModalContainerProps {
  children: ReactNode;
  backgroundColor?: string;
  width?: string;
  className?: string;
}

export const ModalContainer: FC<ModalContainerProps> = ({ children, backgroundColor, width, ...props }) => {
  const theme = useTheme();
  return (
    <Wrapper width={width} backgroundColor={backgroundColor} theme={theme} {...props}>
      {children}
    </Wrapper>
  );
};

ModalContainer.defaultProps = {
  width: '486px',
};
