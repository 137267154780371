import React, { ReactNode } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { CalendarType } from '../../Calendar';
import { Text, TextType } from '../../../text';
import { CalendarImage } from '../CalendarImage';
import { ArrowImage } from '../ArrowImage';

const Wrapper = styled.div`
  width: ${(props: any) =>
    (props.type === CalendarType.DATE_SELECTION || props.type === CalendarType.MONTH_SELECTION) && !props.inline
      ? '210px'
      : (props.type === CalendarType.DATE_RANGE || props.type === CalendarType.DATE_RANGE_DOUBLE) && !props.inline
      ? 'max-content'
      : props.inline
      ? 'max-content'
      : ''};
  min-width: ${(props: any) =>
    (props.type === CalendarType.DATE_RANGE || props.type === CalendarType.DATE_RANGE_DOUBLE) && !props.inline
      ? '250px'
      : 'none'};
  height: 40px;
  box-sizing: border-box;
  border: 1px solid;
  border-color: ${(props: any) => (props.isOpen ? props.theme.colors.primary : props.theme.colors.gray3)};
  border-radius: ${(props: any) => props.theme.borderRadius.primary};
  position: relative;
  cursor: ${(props: any) => (props.isOpen ? 'auto' : 'pointer')};
  padding: 8px 36px;
  display: flex;
  align-items: center;
  transition: all 0.3s linear;
`;
const CalendarImageWrapper = styled.div`
  width: max-content;
  height: 100%;
  display: ${(props: any) => (props.inline ? 'none' : 'flex')};
  align-items: center;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 8px;
  & > svg > path {
    stroke: ${(props: any) => props.theme.colors.primary};
  }
`;
const ArrowImageWrapper = styled.div`
  width: max-content;
  height: 100%;
  display: ${(props: any) => (props.inline ? 'none' : 'flex')};
  align-items: center;
  position: absolute;
  top: 0;
  right: 17.5px;
  z-index: 10;
  & > svg {
    width: 5px;
    height: 10px;
    transition: all 0.3s ease;
    transform: ${(props: any) => (props.isOpen ? 'rotate(90deg)' : 'rotate(270deg)')};
  }
  & > svg > path {
    stroke: ${(props: any) => props.theme.colors.primary};
  }
`;

export const CustomInput = ({
  value,
  type,
  onClick,
  inline,
  isOpen,
  iconComponent,
  ...props
}: {
  value: string;
  type: CalendarType;
  onClick: () => void;
  inline?: boolean;
  isOpen?: boolean;
  iconComponent?: ReactNode;
  className?: string;
}) => {
  const theme = useTheme();
  const { black } = theme.colors;

  return (
    <Wrapper
      className={'custom-input'}
      type={type}
      theme={theme}
      inline={inline}
      isOpen={isOpen}
      onClick={onClick}
      {...props}
    >
      <CalendarImageWrapper theme={theme} inline={inline}>
        {iconComponent ? iconComponent : <CalendarImage />}
      </CalendarImageWrapper>
      <Text type={TextType.TEXT_14_BLACK} color={black}>
        {value}
      </Text>
      <ArrowImageWrapper theme={theme} isOpen={isOpen} inline={inline}>
        <ArrowImage />
      </ArrowImageWrapper>
    </Wrapper>
  );
};
