import React, { FC } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { Arrow } from './components/arrow/Arrow';

export enum ArrowButtonType {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

const Button = styled.div`
  width: 10px;
  background-color: transparent;
  display: flex;
  align-items: center;
  cursor: pointer;
  transform: ${(props: any) => (props.type === ArrowButtonType.LEFT ? '' : 'rotate(180deg)')};
  & > svg > path {
    stroke: ${(props: any) => props.theme.colors.primary};
    transition: stroke 0.3s ease;
  }
  &:hover > svg > path {
    stroke: ${(props: any) => props.theme.colors.secondary3};
  }
`;

export interface ArrowButtonProps {
  type: ArrowButtonType;
  onClick: () => void;
  className?: string;
}

export const ArrowButton: FC<ArrowButtonProps> = ({ type, onClick, ...props }) => {
  const theme = useTheme();
  return (
    <Button onClick={onClick} type={type} {...props} theme={theme}>
      <Arrow />
    </Button>
  );
};

ArrowButton.defaultProps = {
  type: ArrowButtonType.LEFT,
};
