import React, { FC, ReactNode, useState } from 'react';
// @ts-ignore
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import moment from 'moment';
import { CustomInput } from './components/customInput/CustomInput';

export enum CalendarType {
  DATE_SELECTION = 'DATE_SELECTION',
  MONTH_SELECTION = 'MONTH_SELECTION',
  DATE_RANGE = 'DATE_RANGE',
  DATE_RANGE_DOUBLE = 'DATE_RANGE_DOUBLE',
}

const Wrapper = styled.div`
  width: ${(props: any) =>
    (props.type === CalendarType.DATE_SELECTION || props.type === CalendarType.MONTH_SELECTION) && !props.inline
      ? '210px'
      : (props.type === CalendarType.DATE_RANGE || props.type === CalendarType.DATE_RANGE_DOUBLE) && !props.inline
      ? '330px'
      : props.inline
      ? 'max-content'
      : ''};
  position: relative;
  .react-datepicker {
    display: flex;
    font-family: Poppins, sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.01px;
    border: none;
    border-radius: ${(props: any) => props.theme.borderRadius.primary};
    box-shadow: ${(props: any) => props.theme.shadows.additionalShadow1};
  }
  .react-datepicker-popper {
    transform: initial !important;
    top: 50px !important;
    margin: 0;
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__header {
    background-color: white;
    border: none;
    border-radius: ${(props: any) => props.theme.borderRadius.primary};
    font-size: 20px;
    padding: 22px 24px 22px 24px;
  }
  .react-datepicker__navigation--previous {
    border-right-color: primary;
    left: 23px;
    top: 26px;
    outline: none;
    transition: border-right-color 0.3s ease;
    &:hover {
      border-right-color: secondary3;
    }
  }
  .react-datepicker__current-month {
    color: black;
    font-family: Poppins, sans-serif;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.02px;
    border-bottom: 1px solid ${(props: any) => props.theme.colors.gray4};
    padding: 0px 0px 22px 0px;
    margin: 0px 0px 22px 0px;
  }
  .react-datepicker__navigation--next {
    border-left-color: primary;
    right: 23px;
    top: 26px;
    outline: none;
    transition: border-left-color 0.3s ease;
    &:hover {
      border-left-color: secondary3;
    }
  }
  .react-datepicker__day-names {
    display: flex;
    justify-content: space-around;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    & > div {
      color: gray1;
    }
  }
  .react-datepicker__day-name {
    width: initial;
  }
  .react-datepicker__week {
    display: flex;
    justify-content: space-around;
  }
  .react-datepicker__day {
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    border-radius: ${(props: any) => props.theme.borderRadius.round};
    transition: all 0.3s ease;
    outline: none;
  }
  .react-datepicker__day:hover {
    background-color: primary !important;
    color: white;
  }
  .react-datepicker__day:focus {
    background-color: primary !important;
  }
  .react-datepicker__day--selected {
    background-color: primary !important;
    color: white;
    font-weight: 600;
  }
  .react-datepicker__day--selected:hover {
    background-color: primary !important;
    border-radius: ${(props: any) => props.theme.borderRadius.round};
    color: white;
  }
  .react-datepicker__month {
    background-color: white;
    border-top: ${(props: any) => (props.type === CalendarType.MONTH_SELECTION ? '1px solid' : 'none')};
    border-color: gray4;
    border-radius: 0px 0px ${(props: any) => props.theme.borderRadius.primary + ' ' + props.theme.borderRadius.primary};
    padding: ${(props: any) => (props.type === CalendarType.MONTH_SELECTION ? '19.5px 0 21px 0' : '0 22px 21px 22px')};
    margin: ${(props: any) => (props.type === CalendarType.MONTH_SELECTION ? '0 22px 0 22px' : '0')};
  }
  .react-datepicker__day--outside-month {
    visibility: hidden;
  }
  .react-datepicker__day--in-range {
    background-color: green5 !important;
  }
  .react-datepicker__day--in-selecting-range {
    background-color: green5;
  }
  .react-datepicker__day--range-start {
    background-color: primary !important;
    border-radius: ${(props: any) => props.theme.borderRadius.round};
  }
  .react-datepicker__day--range-end {
    background-color: primary !important;
    color: white;
    border-radius: ${(props: any) => props.theme.borderRadius.round};
  }
  .react-datepicker__day--keyboard-selected {
    background-color: initial;
  }
  .react-datepicker__month-container {
    width: 330px;
  }
  .react-datepicker__month-wrapper {
    display: flex;
    justify-content: space-around;
  }
  .react-datepicker__month-text {
    width: 88px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #e4e4eb;
    border-radius: ${(props: any) => props.theme.borderRadius.primary};
    margin: 8px 4px;
    outline: none;
    transition: all 0.3s ease;
    &:hover {
      background-color: primary;
      color: white;
    }
  }
  .react-datepicker__month--selected {
    background-color: primary !important;
    color: white !important;
  }
  .react-datepicker__month-text--keyboard-selected {
    background-color: transparent;
    color: black;
  }
  .react-datepicker__day--disabled {
    background-color: transparent !important;
    color: gray3;
    pointer-events: none;
  }
`;
const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  display: flex;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  border-radius: ${(props: any) => props.theme.borderRadius.primary};
  box-shadow: ${(props: any) => props.theme.shadows.additionalShadow2};
  padding: 8px 36px;
  outline: none;
  cursor: pointer;
` as typeof DatePicker;

export interface CalendarProps {
  type: CalendarType;
  startDate?: any;
  endDate?: any;
  setStartDate?: (value: React.SetStateAction<Date>) => void;
  setEndDate?: (value: React.SetStateAction<null>) => void;
  dateFormat?: string;
  iconComponent?: ReactNode;
  minDate?: any;
  maxDate?: any;
  inline?: boolean;
  showDisabledMonthNavigation?: boolean;
  fixedHeight?: boolean;

  className?: string;
}

export const Calendar: FC<CalendarProps> = ({
  type,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  dateFormat,
  iconComponent,
  minDate,
  maxDate,
  inline,
  showDisabledMonthNavigation,
  fixedHeight,
  ...props
}) => {
  const theme = useTheme();
  const [startDateLocal, setStartDateLocal] = useState(new Date());
  const [endDateLocal, setEndDateLocal] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const onChange = (dates: any) => {
    const [start, end] = dates;
    setStartDate ? setStartDate(start) : setStartDateLocal(start);
    setEndDate ? setEndDate(end) : setEndDateLocal(end);
  };

  const inputValue =
    moment(`${startDate ? startDate : startDateLocal}`).format(dateFormat ? dateFormat : 'D MMM yyyy') +
    ' - ' +
    moment(`${endDate ? endDate : endDateLocal}`).format(dateFormat ? dateFormat : 'D MMM yyyy');

  return (
    <Wrapper isOpen={isOpen} type={type} {...props} theme={theme} inline={inline}>
      <StyledDatePicker
        customInput={
          <CustomInput
            type={type}
            value={
              type === CalendarType.DATE_RANGE || type === CalendarType.DATE_RANGE_DOUBLE
                ? endDate
                  ? endDate
                  : endDateLocal
                  ? inputValue
                  : moment(`${startDate ? startDate : startDateLocal}`).format(dateFormat ? dateFormat : 'D MMM yyyy')
                : type === CalendarType.MONTH_SELECTION
                ? moment(`${startDate ? startDate : startDateLocal}`).format(dateFormat ? dateFormat : 'MMMM')
                : type === CalendarType.DATE_SELECTION
                ? moment(`${startDate ? startDate : startDateLocal}`).format(
                    dateFormat ? dateFormat : 'ddd, D MMM yyyy',
                  )
                : ''
            }
            inline={inline}
            isOpen={isOpen}
            onClick={() => {}}
            iconComponent={iconComponent}
          />
        }
        selected={startDate ? startDate : startDateLocal}
        onChange={
          type === CalendarType.DATE_SELECTION || type === CalendarType.MONTH_SELECTION
            ? (date: any) => (setStartDate ? setStartDate(date) : setStartDateLocal(date))
            : type === CalendarType.DATE_RANGE || type === CalendarType.DATE_RANGE_DOUBLE
            ? onChange
            : () => console.log('You should enter type of calendar!')
        }
        onCalendarOpen={(e: any) => {
          setIsOpen(true);
        }}
        onCalendarClose={() => setIsOpen(false)}
        startDate={
          type === CalendarType.DATE_RANGE || type === CalendarType.DATE_RANGE_DOUBLE
            ? startDate
              ? startDate
              : startDateLocal
            : null
        }
        endDate={
          type === CalendarType.DATE_RANGE || type === CalendarType.DATE_RANGE_DOUBLE
            ? endDate
              ? endDate
              : endDateLocal
            : null
        }
        selectsRange={type === CalendarType.DATE_RANGE || type === CalendarType.DATE_RANGE_DOUBLE ? true : false}
        formatWeekDay={(nameOfDay: any) => nameOfDay.substr(0, 1)}
        showMonthYearPicker={type === CalendarType.MONTH_SELECTION ? true : false}
        showFullMonthYearPicker={type === CalendarType.MONTH_SELECTION ? true : false}
        value={
          type === CalendarType.DATE_RANGE || type === CalendarType.DATE_RANGE_DOUBLE
            ? endDate
              ? endDate
              : endDateLocal
              ? inputValue
              : moment(`${startDate ? startDate : startDateLocal}`).format(dateFormat ? dateFormat : 'D MMM yyyy')
            : type === CalendarType.MONTH_SELECTION
            ? moment(`${startDate ? startDate : startDateLocal}`).format(dateFormat ? dateFormat : 'MMMM')
            : type === CalendarType.DATE_SELECTION
            ? moment(`${startDate ? startDate : startDateLocal}`).format(dateFormat ? dateFormat : 'ddd, D MMM yyyy')
            : ''
        }
        shouldCloseOnSelect={type === CalendarType.DATE_RANGE || type === CalendarType.DATE_RANGE_DOUBLE ? false : true}
        monthsShown={type === CalendarType.DATE_RANGE_DOUBLE ? 2 : 1}
        theme={theme}
        minDate={minDate}
        maxDate={maxDate}
        inline={inline}
        showDisabledMonthNavigation={showDisabledMonthNavigation}
        fixedHeight={fixedHeight}
      />
    </Wrapper>
  );
};
