import { ButtonIconType, CommonButton } from '@cthings.co/buttons';
import { Text, TextType } from '@bit/first-scope.text';
import { stringifyUrl } from 'query-string';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from '@cthings.co/router-dom';
import styled from '@cthings.co/styled-components';
import { resolveIncident, useGetIncident } from '../../../api/incidents';
import { useLoggedUserInfo } from '../../../api/manage/manageUsers';
import { selectLanguageStrings } from '../../../app/state/user';
import { useNotifierFunctions } from '../../../features/notifier2';
import { useTableFunctions, withTableContextProvider } from '../../../features/universalTable/context';
import { getPath } from '../../../routes';
import { injectPathParams } from '../../../routes/paths';
import { View } from '../../../routes/routeInterfaces';
import { media } from '@cthings.co/styles-utils';
import { useMediaType } from '@cthings.co/styles-utils';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { useHistory } from '../../../utils/react-router-dom-abstraction';
import { useQueryParams } from '../../../utils/useQueryParams';
import { ActionModal } from './components/actionModal/ActionModal';
import { MobileDetails } from './components/mobileDetails/MobileDetails';
import { Table } from './components/table/Table';

const ExtraWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

const TitleWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  ${media.tablet} {
    position: fixed;
    z-index: 1;
    padding-right: 16px;
    padding-left: 16px;
    margin-bottom: 0;
    &:after {
      content: '';
      position: absolute;
      height: 1px;
      width: 100%;
      background-color: ${colorFetch('gray3')};
      left: 50%;
      bottom: -12px;
      transform: translateX(-50%);
    }
    &:before {
      content: '';
      position: absolute;
      z-index: -1;
      background-color: ${colorFetch('gray5')};
      left: 0;
      right: 0;
      bottom: -12px;
      top: -32px;
    }
  }
  ${media.phone} {
    &:before {
      top: -24px;
    }
  }
`;

// @ts-ignore
const StyledText = styled(Text)`
  font-weight: 600;
  font-size: 32px;
  line-height: 32px;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  &.placeholder {
    height: calc(100% - 121px);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  ${media.desktop} {
    max-width: 1024px;
  }
  ${media.tablet} {
    margin-top: 44px;
  }
`;

// @ts-ignore
const AddButton = styled(CommonButton)`
  ${media.tablet} {
    width: 32px;
    height: 32px;
    & > span {
      display: none;
    }
    & > div > svg {
      margin: 0 !important;
    }
  }
`;

// @ts-ignore
const ButtonText = styled(Text)`
  ${media.tablet} {
    display: none;
  }
`;

const IncidentsPlain = () => {
  const { resetTable, startAddProcess } = useTableFunctions();
  const { addNotification } = useNotifierFunctions();
  const queryParams = useQueryParams();
  const params = useParams();

  const { incident_id } = queryParams || {};

  const toIncidents = View.USER_INCIDENTS;

  const history = useHistory();

  const { tablet } = useMediaType();

  const languageStrings = useSelector(selectLanguageStrings);

  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isOpenResolveModal, setIsOpenResolveModal] = useState(false);
  const [isOpenMobileDetailsModal, setIsOpenMobileDetailsModal] = useState(false);

  const [isLoadingInProcess, setLoadingInProcess] = useState(false);
  const incidentData = useGetIncident(incident_id, setLoadingInProcess);
  const [localIncidentData, setLocalIncident] = useState(incidentData);

  const userInfo = useLoggedUserInfo();

  const openModal = () => {
    setIsOpenResolveModal(true);
  };

  const closeResolveModal = () => {
    setIsOpenResolveModal(false);

    const { incident_id: _, ...restQueries } = queryParams || {};
    history.push(
      stringifyUrl({
        url: injectPathParams(getPath(toIncidents), params),
        query: restQueries,
      }),
    );
  };

  const handleResolveIncident = (comment: string) => {
    if (localIncidentData) {
      resolveIncident(
        localIncidentData.id,
        comment ? { text: comment } : null,
        setLocalIncident,
        addNotification,
        languageStrings,
      );
      closeResolveModal();
    }
  };

  const openDetailsMobileModal = (id: string) => {
    setIsOpenMobileDetailsModal(true);

    history.push(
      stringifyUrl({ url: injectPathParams(getPath(toIncidents), params), query: { ...queryParams, incident_id: id } }),
    );
  };

  const closeDetailsMobileModal = () => {
    setIsOpenMobileDetailsModal(false);
    const { incident_id: _, ...restQueries } = queryParams || {};

    history.push(
      stringifyUrl({
        url: injectPathParams(getPath(toIncidents), params),
        query: restQueries,
      }),
    );
  };

  useEffect(() => {
    setLocalIncident(incidentData);
  }, [incidentData]);

  useEffect(() => {
    return () => {
      resetTable();
    };
  }, []);

  useEffect(() => {
    if (!!incident_id && tablet) {
      setIsOpenMobileDetailsModal(true);
    }
  }, [incident_id, tablet]);

  return (
    <ExtraWrapper>
      <TitleWrapper>
        <StyledText type={TextType.TITLE_H1}>{languageStrings.navigationIncidents}</StyledText>
        {isDataLoaded && (
          <AddButton
            onClick={() => {
              startAddProcess();
            }}
            iconType={ButtonIconType.ADD}
            width={'126px'}
          >
            <ButtonText type={TextType.TEXT_14_WHITE} fontSize={'14px'} lineHeight={'16px'}>
              {languageStrings.manageAddButton}
            </ButtonText>
          </AddButton>
        )}
      </TitleWrapper>
      <Wrapper>
        <Table
          data={localIncidentData}
          customRowClick={(value: any) => {
            tablet && openDetailsMobileModal(value.id);
          }}
          incident_id={incident_id || ''}
          setLocalIncident={setLocalIncident}
          openModal={openModal}
          isLoadingInProcess={isLoadingInProcess}
          languageStrings={languageStrings}
          setIsDataLoaded={setIsDataLoaded}
          isOpenResolveModal={isOpenResolveModal}
        />
      </Wrapper>
      <ActionModal
        isOpenModal={isOpenResolveModal}
        closeModal={closeResolveModal}
        acceptOnClick={handleResolveIncident}
        data={localIncidentData}
        isLoadingInProcess={isLoadingInProcess}
        languageStrings={languageStrings}
      />
      <MobileDetails
        isOpenModal={isOpenMobileDetailsModal}
        closeModal={closeDetailsMobileModal}
        data={localIncidentData}
        isLoadingInProcess={isLoadingInProcess}
        userInfo={userInfo}
        incidentId={incident_id}
        languageStrings={languageStrings}
        handleResolveIncident={handleResolveIncident}
      />
    </ExtraWrapper>
  );
};

export const Incidents = withTableContextProvider(IncidentsPlain);
