import React, { FC, useState } from 'react';
// @ts-ignore
import styled, { ThemeProvider, useTheme } from '@xstyled/styled-components';
import { motion } from 'framer-motion';

const Container = styled.div`
  width: auto;
  height: auto;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Button = styled(motion.div)`
  width: 16px;
  height: 16px;
  border: 1px solid #33894e;
  border-radius: ${(props: any) => props.theme.borderRadius.round};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InnerCircle = styled(motion.div)`
  width: 8px;
  height: 8px;
  display: flex;
  border-radius: ${(props: any) => props.theme.borderRadius.round};
  box-shadow: none;
  background-color: white;
`;

const LabelContainer = styled(motion.div)`
  width: auto;
  height: auto;
  padding: 2px;
  display: flex;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 12px;
  font-style: normal;
  margin-left: 8px;
`;

export interface RadioButtonProps {
  toggle: boolean;
  disabled: boolean;
  label: string;
  handleToggle: (event: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
}

export const RadioButton: FC<RadioButtonProps> = ({ toggle, label, handleToggle, disabled = false, ...props }) => {
  const [toggled, setToggle] = useState(toggle);
  const [disabledState] = useState(disabled);
  const theme = useTheme();
  const { primary } = theme.colors;

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setToggle(!toggled);
    handleToggle(e);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Button
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            disabled ? e.preventDefault() : handleClick(e);
          }}
          animate={{
            backgroundColor: toggled ? primary : 'rgb(255, 255, 255)',
            opacity: disabledState ? 0.3 : 1,
          }}
          transition={{ duration: 0.1, ease: 'linear' }}
          theme={theme}
        >
          <InnerCircle
            style={{
              boxShadow: toggled ? '0px 1px 2px rgba(40, 41, 61, 0.4), 0px 2px 4px rgba(96, 97, 112, 0.2)' : 'none',
            }}
            animate={{
              opacity: toggled ? 1 : 0,
            }}
            theme={theme}
          ></InnerCircle>
        </Button>
        <LabelContainer
          animate={{
            opacity: disabledState ? 0.3 : 1,
          }}
        >
          {label}
        </LabelContainer>
      </Container>{' '}
    </ThemeProvider>
  );
};

function handleToggle() {
  console.log('Toggled');
}

RadioButton.defaultProps = {
  toggle: false,
  label: 'Label',
  disabled: false,
  handleToggle: handleToggle,
};
