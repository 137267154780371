import React, { CSSProperties, FC, ReactNode, SyntheticEvent } from 'react';
import styled from '@cthings.co/styled-components';
import { ReactComponent as Download } from '../../assets/download.svg';
import { Colors } from '../../types/colors';
import { useTheme } from '@cthings.co/styled-components';

type DownloadLinkAProps = {
  width: string;
  height: string;
  color: string;
  hoverColor: string;
  backgroundColor: string;
  borderColor: string;
  borderRadius: string;
  backgroundColorHover: string;
};

const DownloadLinkA = styled.a<DownloadLinkAProps>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};

  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};

  border: ${({ borderColor }) => (borderColor ? `1px solid ${borderColor}` : 'none')};
  border-radius: ${({ borderRadius }) => borderRadius};

  text-decoration: none;
  font-size: 12px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  line-height: 16px;
  letter-spacing: 0.2px;
  transition: all 0.3s linear;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  & > svg > path {
    transition: all 0.3s ease;
    stroke: ${({ color }) => color};
  }

  &:hover {
    color: ${({ hoverColor }) => hoverColor};
    background-color: ${({ backgroundColorHover }) => backgroundColorHover};
    & > svg > path {
      transition: all 0.3s ease;
      stroke: ${({ hoverColor }) => hoverColor};
    }
  }
`;

const WrapperText = styled.div`
  color: inherit;
  margin-left: 5px;
`;

type DownloadColors = Partial<Colors>;

type DownloadLinkProps = {
  downloadUrl: string;
  children: ReactNode;
  colors?: DownloadColors;
  onClick?: (e?: SyntheticEvent<HTMLElement>) => void;
  className?: string;
} & CSSProperties;

export const DownloadLink: FC<DownloadLinkProps> = ({
  downloadUrl,
  onClick,
  children,
  colors = {} as DownloadColors,
  ...props
}) => {
  const theme = useTheme();
  const { pureWhite, primary, secondary2 } = theme.colors;
  const { primary: borderRadius } = theme.borderRadius;
  const mainColor = colors.main || pureWhite;
  const mainHoverColor = colors.mainHover || pureWhite;
  const backgroundColor = colors.background || primary;
  const backgroundColorHover = colors.backgroundHover || secondary2;
  const borderColor = colors.border || primary;

  const handleClick = (e: SyntheticEvent<HTMLElement>) => {
    onClick && onClick(e);
  };

  return (
    <DownloadLinkA
      href={downloadUrl}
      onClick={handleClick}
      target="_blank"
      rel={'noopener noreferer'}
      color={mainColor}
      hoverColor={mainHoverColor}
      backgroundColor={backgroundColor}
      backgroundColorHover={backgroundColorHover}
      borderColor={borderColor}
      borderRadius={borderRadius}
      {...(props as any)}
    >
      <Download />
      {/* @ts-ignore */}
      <WrapperText>{children}</WrapperText>
    </DownloadLinkA>
  );
};
