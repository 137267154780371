import React, { FC, useState } from 'react';
import styled from '@cthings.co/styled-components';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../app/state/user';
import { withLoader } from '../../features/placeholderComponent/loaderFunctions';
import { media } from '@cthings.co/styles-utils';
import { NoDataPlaceholder } from '../nodataPlaceholder/NoDataPlaceholder';
import { PlaceholderType } from '../placeholders/typePlaceholders/placeholdersType';
import { DotsGraph } from './components/dotsGraph/DotsGraph';
import { FiltersEventsModal } from './components/filtersModal/FiltersEventsModal';
import { EventsHeader } from './components/header/EventsHeader';
import { TitleBlock } from './components/titleBlock/TitleBlock';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { LegendsOfEvents } from './components/legendsOfEvents/LegendsOfEvents';
import { EventsGraphFilters } from '../../containers/common/management/deviceFlowPages/insightsDetails';

export enum GraphType {
  WATER_LEVEL = 'WATER_LEVEL',
  TEMPERATURE = 'TEMPERATURE',
  EVENTS = 'EVENTS',
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

type WrapperPrrops = {
  borderRadius: string;
  shadows: string;
};

const Wrapper = styled.div<WrapperPrrops>`
  width: 100%;
  height: auto;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colorFetch('white')};
  border-radius: ${({ borderRadius }) => borderRadius};
  box-shadow: ${({ shadows }) => shadows};
  flex-direction: column;
`;

const LineWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 0px 0 20px;
  overflow: hidden;
  position: relative;

  /** Use this setting to prevent crop of the line on extreme value */
  & > div > div > svg {
    overflow: visible;
  }
  ${media.tablet} {
    width: 100%;
  }
`;

const TableWrapper = styled.div`
  position: relative;
  width: 100%;
`;

interface DataType {
  data: any[];
  device_id: string;
  header: string;
  ts_from: Date;
  ts_to: Date;
  defaultDate: { start: string; end: string };
}

export interface EventsGraphProps {
  filters: EventsGraphFilters;
  savedFilters: EventsGraphFilters;
  data: DataType;
  header: string;
  handleChangeCurrentFilters: any;
  setCurrentFilters?: any;
  type: GraphType;
  labels?: { xAxis: string; yAxis: string };
  className?: string;
  applyOnClick: () => void;
  isLoadingInProcess?: boolean;
  applyingStatus: number;
  setApplyingStatus: any;
  isSmartCapDevice?: boolean;
}

const EventsGraphPlain: FC<EventsGraphProps> = ({
  data,
  header,
  filters,
  savedFilters,
  handleChangeCurrentFilters,
  setCurrentFilters,
  type,
  labels,
  applyOnClick,
  isLoadingInProcess,
  applyingStatus,
  setApplyingStatus,
  isSmartCapDevice,
  ...props
}) => {
  const theme = useTheme();
  const { additional16 } = theme.borderRadius;
  const { additionalShadow2 } = theme.shadows;

  const languageStrings = useSelector(selectLanguageStrings);

  const [isOpenFiltersModal, setIsOpenFiltersModal] = useState(false);
  const [isApplyButtonActive, setIsApplyButtonActive] = useState(false);

  const [savedData, setSavedData] = useState({});

  const handleOpenFilterModal = () => {
    setIsOpenFiltersModal(true);
    setSavedData(filters);
  };

  const handleCloseFiltersModal = () => {
    setIsOpenFiltersModal(false);
    setCurrentFilters(savedData);
  };

  const handleApply = () => {
    applyOnClick();
    setIsOpenFiltersModal(false);
  };

  const setFilters = (filterName: string, value: { tooltip: string; id: string; key: string }) => {
    handleChangeCurrentFilters(filterName, value);

    setIsApplyButtonActive(true);
    setApplyingStatus(0);
  };

  return (
    <Container>
      <TitleBlock
        type={GraphType.EVENTS}
        data={data}
        header={header}
        filters={filters}
        applyingStatus={applyingStatus}
        handleOpenFilterModal={handleOpenFilterModal}
      />
      <Wrapper {...props} borderRadius={additional16} shadows={additionalShadow2}>
        <TableWrapper>
          <EventsHeader
            data={data}
            filters={filters}
            savedFilters={savedFilters}
            setCurrentFilters={setCurrentFilters}
            setFilters={setFilters}
            applyOnClick={() => {
              setIsApplyButtonActive(false);
              handleApply();
            }}
            applyingStatus={applyingStatus}
            isApplyButtonActive={isApplyButtonActive}
          />
          <LineWrapper>
            {data?.data?.length ? (
              <DotsGraph data={data} labels={labels} type={type} />
            ) : (
              <NoDataPlaceholder languageStrings={languageStrings} />
            )}
          </LineWrapper>
        </TableWrapper>
      </Wrapper>
      <FiltersEventsModal
        isOpenModal={isOpenFiltersModal}
        closeModal={handleCloseFiltersModal}
        handleApply={handleApply}
        filters={filters}
        setCurrentFilters={setCurrentFilters}
        setFilters={setFilters}
        data={data}
        applyingStatus={applyingStatus}
        isApplyButtonActive={isApplyButtonActive}
        languageStrings={languageStrings}
      />
      <LegendsOfEvents isSmartCapDevice={isSmartCapDevice} />
    </Container>
  );
};

export const EventsGraph = withLoader(undefined, PlaceholderType.GRAPH)(EventsGraphPlain);

EventsGraph.defaultProps = {
  header: 'Events over time',
};
