import { Text, TextType } from '@bit/first-scope.text';
import { useTheme } from '@cthings.co/styled-components';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import styled from '@cthings.co/styled-components';
import { selectLanguageStrings } from '../../../../../../../../../app/state/user';
import { CopyButton } from '../../../../../../../../../components/copyButton/CopyButton';
import { useNotifierFunctions } from '../../../../../../../../../features/notifier2';
import { media } from '@cthings.co/styles-utils';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

export enum MobileModalType {
  EDIT_NAME = 'EDIT_NAME',
  EDIT_GROUP = 'EDIT_GROUP',
}

const TitleWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${media.tablet} {
    width: max-content;
  }
`;

const NameWrapper = styled.div`
  width: auto;
  display: flex;
  flex-direction: flex-start;
  align-items: center;
  ${media.tablet} {
    width: 100%;
  }
` as any; // @TODO fix type

const CopiedWrapper = styled.div`
  width: max-content;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    & > span {
      color: ${colorFetch('gray1')};
    }
    & div > svg > path {
      transition: all 0.3s linear;
      stroke: ${colorFetch('gray3')};
    }
  }
`;

type StyledTextProps = {
  heightOfTitle?: number;
};

// @ts-ignore
const StyledText = styled(Text)<StyledTextProps>`
  max-height: 96px;
  margin: 0 10px !important;
  line-height: 32px;
  word-break: break-all;
  text-align: center;
  overflow-y: ${({ heightOfTitle }) => (heightOfTitle && heightOfTitle > 95 ? 'auto' : 'inherit')};
  &::-webkit-scrollbar {
    width: 4px;
    background: ${colorFetch('gray4')};
    opacity: 0.6;
    border-radius: ${({ theme }) => theme.borderRadius.primary};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${colorFetch('gray2')};
    border-radius: ${({ theme }) => theme.borderRadius.primary};
  }
  ${media.tablet} {
    font-size: 20px !important;
  }
`;

export const NameOfDevice = ({ name }: { name: string }) => {
  const theme = useTheme();
  const titleRef = useRef<HTMLDivElement>();
  const heightOfTitle = titleRef?.current?.clientHeight;
  const { addNotification } = useNotifierFunctions();
  const languageStrings = useSelector(selectLanguageStrings);

  const handleCopyText = async (copiedText: string) => {
    await navigator.clipboard.writeText(copiedText);
    addNotification({ message: languageStrings.copiedMessage, type: 'info' });
  };

  return (
    <TitleWrapper>
      {name ? (
        <NameWrapper ref={titleRef}>
          <CopiedWrapper
            onClick={() => {
              handleCopyText(name);
            }}
          >
            <StyledText theme={theme} type={TextType.TITLE_H1} heightOfTitle={heightOfTitle}>
              {name}
            </StyledText>
            <CopyButton />
          </CopiedWrapper>
        </NameWrapper>
      ) : (
        <StyledText type={TextType.TITLE_H1} heightOfTitle={heightOfTitle}>
          N/A
        </StyledText>
      )}
    </TitleWrapper>
  );
};
