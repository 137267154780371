import styled from '@cthings.co/styled-components';
import { media } from '@cthings.co/styles-utils';
import { Text } from '@bit/first-scope.text';

export const ActionWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  ${media.desktop} {
    max-width: 1024px;
  }
  ${media.tablet} {
    padding-top: 80px;
  }
`;

type UpperWrapperProps = {
  withSmartSearch?: boolean;
};

export const UpperWrapper = styled.div<UpperWrapperProps>`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ withSmartSearch }) => (withSmartSearch ? '36px' : '44px')};
  ${media.tablet} {
    display: none;
  }
  & > span {
    ${media.tablet} {
      display: none;
    }
  }
`;

// @ts-ignore
export const StyledText = styled(Text)`
  font-weight: 600;
  font-size: 32px;
  line-height: 32px;
`;
