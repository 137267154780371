import { EditButton } from '@bit/first-scope.edit-button';
import { Text, TextType } from '@bit/first-scope.text';
import { useTheme } from '@cthings.co/styled-components';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from '@cthings.co/styled-components';
import { selectLanguageStrings } from '../../../../../../../../../app/state/user';
import { CopyButton } from '../../../../../../../../../components/copyButton/CopyButton';
import { useNotifierFunctions } from '../../../../../../../../../features/notifier2';
import { media } from '@cthings.co/styles-utils';

import { FieldsType, isValidField } from '../../../../../../../../../utils/validation';
import { EditModal, EditModalType } from '../../../editModal/EditModal';
import { getTextWidth } from '@cthings.co/utils';
import Tooltip, { TooltipPlace, useTooltip } from '../../../../../../../../../components/tooltip';

const TitleWrapper = styled.div`
  overflow-x: hidden;
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${media.tablet} {
    width: max-content;
  }
`;

type NameWrapperProps = {
  isEditInProgress: boolean;
};

const NameWrapper = styled.div<NameWrapperProps>`
  max-width: ${({ isEditInProgress }) => (isEditInProgress ? 'calc(400px - 45px)' : '400px')};
  width: 100%;
  display: flex;
  flex-direction: flex-start;
  align-items: center;
`;

// @ts-ignore
const StyledText = styled(Text)`
  margin: 0 10px !important;
  line-height: 32px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${media.tablet} {
    font-size: 20px !important;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  & > div > svg {
    width: 18px;
    height: 18px;
  }
`;

export const NameOfDevice = ({
  name,
  handleOnChangeField,
  accessData,
}: {
  name: string;
  handleOnChangeField: (value: string) => void;
  accessData?: { view: boolean; edit: boolean; delete: boolean };
}) => {
  const theme = useTheme();
  const { gray2, gray3 } = theme.colors;

  const { addNotification } = useNotifierFunctions();

  const [tooltipIds] = useTooltip();

  const languageStrings = useSelector(selectLanguageStrings);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [textareaValue, setTextareaValue] = useState(name);
  const [savedString, setSavedString] = useState(name);
  const [validatingIsStarted, setValidatingIsStarted] = useState(false);

  const textWidth = getTextWidth(name);

  const isFullContentVisible = textWidth <= 165;

  const openModalHandle = () => {
    setIsOpenModal(true);
  };

  const closeModalHandle = () => {
    revertHandle();
    setIsOpenModal(false);
    setValidatingIsStarted(false);
  };

  const onChangeHandle = (value: any) => {
    setTextareaValue(value);
  };

  const revertHandle = useCallback(() => {
    setTextareaValue(savedString);
  }, [savedString]);

  const onSaveClickHandle = () => {
    setValidatingIsStarted(true);
    if (isValidField(textareaValue, FieldsType.TEXT)) {
      const newValue = textareaValue.trim();
      handleOnChangeField(newValue);
      setSavedString(newValue);
      setIsOpenModal(false);
      setValidatingIsStarted(false);
    }
  };

  const copyTextHandle = async (copiedText: string) => {
    await navigator.clipboard.writeText(copiedText);
    addNotification({ message: languageStrings.copiedMessage, type: 'info' });
  };

  useEffect(() => {
    setTextareaValue(name);
    setSavedString(name);
  }, [name]);

  return (
    <TitleWrapper>
      <NameWrapper isEditInProgress={isOpenModal}>
        {!isFullContentVisible ? (
          <Tooltip id={tooltipIds[0]} text={name} place={TooltipPlace.BOTTOM} width={'250px'}>
            <StyledText type={TextType.TITLE_H1}>{textareaValue}</StyledText>
          </Tooltip>
        ) : (
          <StyledText type={TextType.TITLE_H1}>{textareaValue || 'N/A'}</StyledText>
        )}
        {textareaValue && !isOpenModal && (
          <ButtonsWrapper>
            <CopyButton
              onClick={() => {
                copyTextHandle(textareaValue);
              }}
              isEditName={isOpenModal}
            />
            <EditButton tooltipText="" onClick={openModalHandle} color={gray2} colorHover={gray3} />
          </ButtonsWrapper>
        )}
      </NameWrapper>
      <EditModal
        type={EditModalType.EDIT_NAME}
        data={textareaValue}
        isOpenModal={isOpenModal}
        onClose={closeModalHandle}
        onSave={onSaveClickHandle}
        handleOnChangeField={onChangeHandle}
        languageStrings={languageStrings}
        validatingIsStarted={validatingIsStarted}
      />
    </TitleWrapper>
  );
};
