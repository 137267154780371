import React, { FC, useState, useEffect } from 'react';
import styled from '@cthings.co/styled-components';
import { Text, TextType } from '@bit/first-scope.text';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { useTheme } from '@cthings.co/styled-components';

type WrapperProps = {
  backgroundColor: string | undefined;
  color: string | undefined;
  type: BadgesType;
};

const Wrapper = styled.div<WrapperProps>`
  width: max-content;
  height: 20px;
  box-sizing: border-box;

  display: flex;
  align-items: center;

  background-color: ${({ backgroundColor }) => backgroundColor};

  border-radius: ${colorFetch('primary')};
  padding: 0 8px;

  cursor: pointer;

  & > span {
    font-weight: 700;
    line-height: 15px;
    color: ${({ color }) => color};
  }
`;

export enum BadgesType {
  MISC_TEAL = 'MISC_TEAL',
  MISC_PURPLE = 'MISC_PURPLE',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  DANGER = 'DANGER',
  PRIMARY = 'PRIMARY',
  REMINDER = 'REMINDER',
}

export interface BadgesProps {
  type: BadgesType;
  content: string;
}

export const Badges: FC<BadgesProps> = ({ type, content }) => {
  const theme = useTheme();

  const [background, setBackground] = useState<string | undefined>('');
  const [fontColor, setFontColor] = useState<string | undefined>('');

  const {
    blue5,
    blue2,
    blue4,
    blue0,
    green5,
    green1,
    orange2,
    orange5,
    red4,
    red1,
    gray6,
    blue3,
    orange3,
  } = theme.colors;

  useEffect(() => {
    switch (type) {
      case BadgesType.MISC_TEAL:
        setBackground(blue5);
        setFontColor(blue2);
        break;
      case BadgesType.MISC_PURPLE:
        setBackground(blue4);
        setFontColor(blue0);
        break;
      case BadgesType.SUCCESS:
        setBackground(green5);
        setFontColor(green1);
        break;
      case BadgesType.WARNING:
        setBackground(orange5);
        setFontColor(orange2);
        break;
      case BadgesType.DANGER:
        setBackground(red4);
        setFontColor(red1);
        break;
      case BadgesType.PRIMARY:
        setBackground(gray6);
        setFontColor(blue3);
        break;
      case BadgesType.REMINDER:
        setBackground(orange5);
        setFontColor(orange3);
        break;
      default:
        setBackground('');
        setFontColor('');
    }
  }, [type]);

  return (
    <Wrapper type={type} backgroundColor={background} color={fontColor} theme={theme}>
      <Text type={TextType.TEXT_10_WHITE}>{content}</Text>
    </Wrapper>
  );
};

Badges.defaultProps = {};
