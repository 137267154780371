/**
 * @description
 * This is one of the core Table components that represents
 * the combination of modals used for adding, editting and deleting
 * particular entries in the Tables.
 * Supports advanced Long tap mobile interactions.
 *
 */

import React, { FC, useCallback, useEffect, useState } from 'react';
import styled from '@cthings.co/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { useTableContext, useTableFunctions } from '../../context';
import {
  ActionConfig,
  ActionType,
  ModalColumnCount,
  RowItemType,
  TableCustomComponentState,
  UpdateUserType,
} from '../../types';
import { DeletePopup } from '../../../../components/deletePopup/DeletePopup';
import { CommonButton } from '@cthings.co/buttons';
import { media } from '@cthings.co/styles-utils';
import { InputModal } from '../../../../components/inputModal/InputModal';
import { usePrevious } from '../../../../utils/usePrevious';
import { ModalContainer } from '../../../../components/newModals/ModalContainer';
import { Modal } from '../../../../components/newModals/Modal';
import { getValFromObj, setValInObj } from '../../../../utils/objectDeconstruct';
import { MobilePortalContainer } from '../../../../components/mobilePortalContainer/MobilePortalContainer';
import { useBlockBodyScroll } from '../../../../utils/useBlockBodyScroll';
import { useNotifierFunctions } from '../../../notifier2';
import { CloseWhiteRoundButton } from '../../../../components/closeWhiteRoundButton/CloseWhiteRoundButton';
import { Select } from '../../../../components/universalSelect/Select';
import { Loader } from '../../../../components/placeholders/Loader';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { useTheme } from '@cthings.co/styled-components';

// @ts-ignore
const StyledCloseWhiteRoundButton = styled(CloseWhiteRoundButton)`
  display: none;
  ${media.tablet} {
    display: flex;
  }
`;

// @ts-ignore
const MobileSelectActionButton = styled(Text)`
  cursor: pointer;
  margin: 10px 16px;
  font-size: 16px;
  line-height: 24px;
` as typeof Text;

const MobileSelectActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const MobileSelectActionBlock = styled.div`
  display: flex;
`;

type StyledButtonProps = {
  isBlocked: boolean;
};

// @TODO fix media types
// @ts-ignore
const StyledButton = styled(CommonButton)<StyledButtonProps>`
  width: 100px;
  margin: 5px 0;
  height: auto;
  padding: 4px 0;
  line-height: 22px;

  ${media.tablet} {
    width: 100%;
    height: 40px;
    pointer-events: ${({ isBlocked }: any) => isBlocked && 'none'};
  } ;
`;

type StyledInputProps = {
  isWide: boolean;
};

// @ts-ignore
const StyledInput = styled(InputModal)<StyledInputProps>`
  width: ${({ isWide }) => (isWide ? '480px' : '230px')};
  margin: 0 0 12px 0;

  ${media.tablet} {
    width: 100%;
  } ;
`;

type StyledSelectProps = {
  isWide: boolean;
};

// @ts-ignore
const StyledSelect = styled(Select)<StyledSelectProps>`
  width: ${({ isWide }) => (isWide ? '480px' : '230px')};
  margin: 0 0 12px 0;
  & > div {
    min-height: 40px;
  }
  ${media.tablet} {
    width: 100%;
  } ;
`;

type CustomBlockProps = {
  isWide: boolean;
  disabled: boolean;
  isFocused?: boolean;
  isError: boolean;
  isShowMessage: boolean;
};

const CustomBlock = styled.div<CustomBlockProps>`
  display: flex;
  flex-direction: column;
  width: ${({ isWide }) => (isWide ? '480px' : '230px')};
  margin: 0 0 12px 0;
  &.custom-select-view {
    & > div > div {
      & > div.select {
        background-color: ${({ disabled, theme }) => (disabled ? colorFetch('gray5')({ theme }) : 'transparent')};
        border: 1px solid
          ${({ disabled, isFocused, isError, theme }) =>
            disabled
              ? colorFetch('gray5')({ theme })
              : isFocused
              ? colorFetch('primary')({ theme })
              : isError
              ? colorFetch('red2')({ theme })
              : colorFetch('gray3')({ theme })};
        & > svg {
          width: 26px;
          height: 26px;
          & > path {
            transition: all 0.3s linear;
            stroke: ${({ disabled, isFocused, isError, theme }) =>
              disabled
                ? colorFetch('gray5')({ theme })
                : isFocused
                ? colorFetch('primary')({ theme })
                : isError
                ? colorFetch('red2')({ theme })
                : colorFetch('gray2')({ theme })};
          }
        }
      }
      & > span {
        display: ${({ isShowMessage }) => (isShowMessage ? 'flex' : 'none')} !important;
      }
    }
  }

  ${media.tablet} {
    width: 100%;
  }
`;

type CustomInnerBlockProps = {
  disabled: boolean;
};

const CustomInnerBlock = styled.div<CustomInnerBlockProps>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: ${({ disabled, theme }) => (disabled ? colorFetch('gray5')({ theme }) : 'transparent')};
  pointer-events: ${({ disabled }) => disabled && 'none'};
  padding: 0;
  cursor: pointer;
  transition: all 0.3s linear;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${media.tablet} {
    width: 100%;
  }
`;

// @ts-ignore
const Title = styled(Text)`
  margin: 0 0 14px !important;
  ${media.phone} {
    margin: 0 16px 14px !important;
  }
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  & > * {
    margin-right: 10px;
    margin-left: 10px;
    ${media.tablet} {
      margin-right: 0;
      margin-left: 0;
    }
  }
  ${media.tablet} {
    margin: 0;
  }
`;

// @ts-ignore
const StyledModal = styled(Modal)`
  z-index: var(--modal-second-layer);
`;

// @ts-ignore
const DeleteModal = styled(Modal)`
  z-index: var(--modal-second-layer);
`;

// @ts-ignore
const StyledModalContainer = styled(ModalContainer)`
  padding: 24px;
  ${media.phone} {
    padding: 24px 0px 30px;
  }
`;

// @ts-ignore
const ScrollableWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  ${media.phone} {
    flex-direction: column;
    flex-wrap: nowrap;
    max-height: 408px;
    overflow-y: auto;
    width: calc(100% - 32px);
    padding: 0px 16px;
  }
`;

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  & > button:nth-child(odd) {
    margin-right: 16px;
  }
  ${media.tablet} {
    width: 100%;
    & > button:nth-child(odd) {
      margin-right: 0px;
    }
  }
  ${media.phone} {
    flex-direction: column-reverse;
  }
`;

const CustomButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 8px;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  min-height: 32px;
  width: 100%;
  ${media.tablet} {
    flex-direction: column-reverse;
  }
  ${media.phone} {
    width: calc(100% - 32px);
    padding: 0px 16px;
  }
`;

// @ts-ignore
const DeletePopupContainer = styled(ModalContainer)`
  max-width: 384px;
  z-index: var(--modal-second-layer);

  ${media.phone} {
    max-width: 100% !important;
  }
`;

export interface ModalSystemProps {
  actionSet: ActionConfig[];
  deleteItems: any;
  fieldNameOfDeletedItems?: string;
  allowDelete: boolean;
  modalColumnCount: ModalColumnCount;
  updateItems: UpdateUserType;
  banAutoFocus?: boolean;
  languageStrings?: any;
  accessData?: { view: boolean; edit: boolean; delete: boolean };
  isPendingRequestFromModalApi?: boolean;
  triggerColumnStructure: () => void;
}

export const ModalSystem: FC<ModalSystemProps> = ({
  actionSet,
  deleteItems,
  fieldNameOfDeletedItems,
  allowDelete,
  updateItems,
  modalColumnCount,
  banAutoFocus,
  languageStrings,
  accessData,
  isPendingRequestFromModalApi,
  triggerColumnStructure,
}) => {
  const inputWidth = 230;
  const flexGap = 20;
  const outerPadding = 24;
  const width = (modalColumnCount - 1) * (inputWidth + flexGap) + inputWidth;
  const containerWidth = width + outerPadding * 2;
  const theme = useTheme();
  const { primary, black1, gray1, pureWhite, red2 } = theme.colors;

  const {
    deleteItem,
    deleteCheckedItems,
    getSelectedItems,
    toggleView,
    getViewedData,
    setField,
    toggleEdit,
    toggleDelete,
    toggleAdd,
    updateLocalItem,
    setLocalDeleteIndex,
    getLocalDeleteName,
    getLocalDeleteId,
    createItem,
    uncheckAll,
    getModalItems,
    getRequiredData,
    toggleMobileSelectMode,
    setValidatingIsStarted,
    toggleUpdaiting,
    setIsSaveButtonDisabled,
  } = useTableFunctions();

  const arr = getModalItems();
  const requiredData = getRequiredData();

  const viewedData = getViewedData();
  const prevViewedData = usePrevious(viewedData);
  const [savedData, setSavedData] = useState(viewedData);
  const [blockButtons, setBlockButtons] = useState(false);
  const { addNotification } = useNotifierFunctions();

  const [
    {
      isViewInProcess,
      openIndex,
      isEditInProcess,
      isAddInProcess,
      isUpdaitingInProcess,
      openId,
      currentModalApiId,
      isDeleteInProcess,
      localDeleteIndex,
      saveToggle,
      isSaveButtonDisabled,
    },
  ] = useTableContext();

  const markedArr = getSelectedItems();
  const localDeleteName = getLocalDeleteName(fieldNameOfDeletedItems);
  const nameList = markedArr.map((item: any) => item.name || item.description || `id: ${item.id}`);
  const idList = markedArr.map((item: any) => item.id);
  const validatedFields = arr.filter(
    (item: any) => !item.disabled && !item.excludedFromAdd && !item.excludedFromModalView,
  );
  const isAllFieldsValidated = validatedFields.length === validatedFields.filter((item: any) => !item.isError).length;
  const isLocalDeleteInProcess = localDeleteIndex !== -1;
  const localDeleteId = getLocalDeleteId();
  const disabled = !isEditInProcess && !isAddInProcess;
  const title = isEditInProcess
    ? languageStrings.insightDetailsDeviceConfigHeaderEdit
    : isAddInProcess
    ? languageStrings.modalTitleAdd
    : languageStrings.modalTitleView;

  const customComponentState =
    isEditInProcess || isAddInProcess ? TableCustomComponentState.EDIT : TableCustomComponentState.VIEW_MODAL;

  const handleGlobalDelete = useCallback(() => {
    const deleteItemRow = () => {
      deleteItems && deleteItems([localDeleteId], deleteItem, deleteCheckedItems, addNotification, languageStrings);
      isViewInProcess && toggleView(null, null);
      setLocalDeleteIndex(-1);
    };
    const deleteMultipleRows = () => {
      deleteItems && deleteItems(idList, deleteItem, deleteCheckedItems, addNotification, languageStrings);
    };
    isDeleteInProcess ? deleteMultipleRows() : deleteItemRow();
    isDeleteInProcess && toggleDelete();
  }, [
    isDeleteInProcess,
    deleteCheckedItems,
    deleteItem,
    isViewInProcess,
    toggleView,
    deleteItems,
    idList,
    toggleDelete,
    setLocalDeleteIndex,
    localDeleteId,
  ]);

  const handleCallback = (callback: any) => {
    callback && callback(viewedData);
  };
  const handleInputChange = (e: any, fieldName: any) => {
    setField(openIndex, e.target.value, fieldName);
  };
  const handleSingleValueChange = useCallback(
    (value: any, fieldName: any) => {
      setField(openIndex, value, fieldName);
    },
    [openIndex, setField],
  );
  const handleLocalUpdate = useCallback(
    (index: number, value: any) => {
      updateLocalItem(index, value);
      toggleEdit();
    },
    [updateLocalItem, toggleEdit],
  );
  const handleLocalCreate = useCallback(
    (id: string, value: any) => {
      createItem(id, value);
      toggleAdd();
    },
    [createItem, toggleAdd],
  );
  const handleSave = useCallback(() => {
    setValidatingIsStarted(true);
    if (openId && updateItems) {
      if (isAllFieldsValidated) {
        toggleUpdaiting(true);
        updateItems(
          openId,
          requiredData,
          openIndex,
          !isAddInProcess ? handleLocalUpdate : handleLocalCreate,
          toggleUpdaiting,
          addNotification,
          setIsSaveButtonDisabled,
          languageStrings,
        );
        setValidatingIsStarted(false);
      }
    }
  }, [openId, requiredData, openIndex, handleLocalUpdate, updateItems, handleLocalCreate, isAddInProcess]);
  const handleRevert = useCallback(() => {
    if (!isAddInProcess) {
      setTimeout(() => {
        handleLocalUpdate(openIndex, savedData);
      }, 10);
    } else {
      setTimeout(() => {
        deleteItem(openId);
        toggleAdd();
      }, 10);
    }
  }, [openIndex, savedData, handleLocalUpdate, isAddInProcess, deleteItem, openId, toggleAdd]);
  const handleDelete = () => {
    setLocalDeleteIndex(openIndex);
  };
  const handleCloseDeleteModal = useCallback(() => {
    isLocalDeleteInProcess && setLocalDeleteIndex(-1);
    isDeleteInProcess && toggleDelete();
  }, [isLocalDeleteInProcess, isDeleteInProcess, toggleDelete, setLocalDeleteIndex]);
  // @TODO Alex work on handleSetValue
  const handleSetValue = useCallback(
    (value: any, fieldName: any) => {
      const deconstructObject: any = (fieldName: any, value: any, path: string[] = []) => {
        const isObj = typeof fieldName === 'object' && fieldName !== null;
        if (isObj) {
          return Object.keys(fieldName).reduce((accumulator: any[], newKey: any) => {
            return [...accumulator, ...deconstructObject(fieldName[newKey], value && value[newKey], [...path, newKey])];
          }, []);
        } else {
          return [{ fieldName, value: value || null, path }];
        }
      };
      const mapping = deconstructObject(fieldName, value);
      mapping.forEach((mappingItem: any) => {
        setField(openIndex, (prevValue: any) => {
          const modifiedValue = setValInObj(mappingItem.path, prevValue, mappingItem.value);
          return modifiedValue;
        });
      });
    },
    [openIndex, setField],
  );
  const handleSelectChange = (value: any, fieldName: any) => {
    setField(openIndex, value.id, fieldName.id);
    setField(openIndex, value.name, fieldName.name);
  };
  const handleJoinedSelectChange = (value: any, fieldName: any) => {
    setField(openIndex, value, fieldName);
  };
  const handleCancel = () => {
    setTimeout(() => {
      uncheckAll();
      toggleMobileSelectMode();
    }, 10);
  };

  useEffect(() => {
    !prevViewedData && viewedData && setSavedData(viewedData);
  }, [viewedData, prevViewedData]);
  useEffect(() => {
    currentModalApiId && currentModalApiId === openId && setSavedData(viewedData);
  }, [currentModalApiId]);
  useEffect(() => {
    setBlockButtons(!isViewInProcess && !isAddInProcess);
  }, [isViewInProcess, isAddInProcess]);

  //will block body scroll when modal is open
  useBlockBodyScroll(isViewInProcess || isAddInProcess);

  return (
    <>
      <StyledModal isOpenModal={isViewInProcess || isAddInProcess}>
        <StyledModalContainer width={`${containerWidth}px`} padding={'24px 16px 30px 16px'}>
          <StyledCloseWhiteRoundButton
            onClick={() => {
              if (isAddInProcess) {
                handleRevert();
                setValidatingIsStarted(false);
              } else if (isEditInProcess) {
                handleRevert();
                setValidatingIsStarted(false);
                toggleView(null, null);
              } else {
                toggleView(null, null);
                setValidatingIsStarted(false);
              }
            }}
          />
          <Wrapper>
            <Title type={TextType.TITLE_H3} color={black1}>
              {title}
            </Title>
            <BodyWrapper>
              <ScrollableWrapper>
                {arr
                  .filter(
                    (elem: any) =>
                      (!isAddInProcess && !elem.excludedFromModalView) || !(elem.disabled || elem.excludedFromAdd),
                  )
                  .map((item: any, index: any) => {
                    const {
                      value,
                      fieldName,
                      label,
                      placeholder,
                      type,
                      selectItems,
                      component,
                      getTextDisplay,
                      modalColumns,
                      pathToSelectItems,
                      defaultValue,
                      disabled: isSingleDisabled,
                      rows,
                      selectItemsModifier,
                      isError,
                      tooltipText,
                      validatingIsStarted,
                      customComponentHasSelectView,
                      updateItem,
                      createItem,
                      deleteItem,
                    } = item;
                    let selectVal = value || defaultValue;
                    let selectList = selectItems;
                    if (type === RowItemType.SELECT || type === RowItemType.JOINED_SELECT) {
                      selectList =
                        selectItems ||
                        (pathToSelectItems && pathToSelectItems.length > 0
                          ? getValFromObj(pathToSelectItems, viewedData) || []
                          : []);
                      if (selectItemsModifier) {
                        selectList = selectItemsModifier(selectList);
                      }
                      const isIncluded = selectList.findIndex((val: any) => val.id === selectVal.id) !== -1;
                      selectList = isIncluded ? selectList : [selectVal, ...selectList];
                    }

                    return !type ||
                      type === RowItemType.INPUT ||
                      type === RowItemType.INPUT_NUMBER ||
                      type === RowItemType.INPUT_PHONE_NUMBER ||
                      type === RowItemType.INPUT_EMAIL ||
                      type === RowItemType.INPUT_CUSTOM ? (
                      <StyledInput
                        key={index}
                        type={type === RowItemType.INPUT_NUMBER ? 'number' : 'text'}
                        name={label}
                        value={getTextDisplay ? getTextDisplay(value) : value}
                        disabled={disabled || isSingleDisabled}
                        placeholder={placeholder}
                        onChange={(e: any) => handleInputChange(e, fieldName)}
                        isWide={modalColumns === 2}
                        rows={rows}
                        startedValidation={validatingIsStarted}
                        isError={isError && (isEditInProcess || isAddInProcess)}
                        isTooltip
                        tooltipText={tooltipText}
                      />
                    ) : type === RowItemType.SELECT ? (
                      <StyledSelect
                        value={selectVal}
                        label={label}
                        disabled={disabled || isSingleDisabled}
                        values={selectList}
                        onChange={(value: any) => handleSelectChange(value, fieldName)}
                        isWide={modalColumns === 2}
                        placeholder={isEditInProcess || isAddInProcess ? placeholder : 'N/A'}
                        banAutoFocus={banAutoFocus}
                        startedValidation={validatingIsStarted}
                        isError={isError && (isEditInProcess || isAddInProcess)}
                        isPendingRequest={isPendingRequestFromModalApi}
                        updateItem={updateItem}
                        createItem={createItem}
                        deleteItem={deleteItem}
                        triggerColumnStructure={triggerColumnStructure}
                        languageStrings={languageStrings}
                      />
                    ) : type === RowItemType.JOINED_SELECT ? (
                      <StyledSelect
                        value={value}
                        label={label}
                        disabled={disabled || isSingleDisabled}
                        values={selectList}
                        onChange={(value: any) => handleJoinedSelectChange(value, fieldName)}
                        isWide={modalColumns === 2}
                        placeholder={isEditInProcess || isAddInProcess ? placeholder : 'N/A'}
                        banAutoFocus={banAutoFocus}
                        startedValidation={validatingIsStarted}
                        isError={isError && (isEditInProcess || isAddInProcess)}
                        isPendingRequest={isPendingRequestFromModalApi}
                        updateItem={updateItem}
                        createItem={createItem}
                        deleteItem={deleteItem}
                        triggerColumnStructure={triggerColumnStructure}
                        languageStrings={languageStrings}
                      />
                    ) : type === RowItemType.CUSTOM ? (
                      <CustomBlock
                        isWide={modalColumns === 2}
                        className={customComponentHasSelectView ? 'custom-select-view' : ''}
                        isError={
                          (customComponentHasSelectView ? isError && validatingIsStarted : isError) &&
                          (isEditInProcess || isAddInProcess)
                        }
                        disabled={disabled || isSingleDisabled}
                        isShowMessage={validatingIsStarted && isError && !disabled}
                      >
                        <Text type={TextType.TEXT_12_BLACK} color={gray1} margin="0 0 4px 6px">
                          {label}
                        </Text>
                        <CustomInnerBlock disabled={disabled || isSingleDisabled}>
                          {component &&
                            component(
                              value,
                              customComponentState,
                              (val: any) => handleSingleValueChange(val, fieldName),
                              saveToggle,
                            )}
                        </CustomInnerBlock>
                      </CustomBlock>
                    ) : null;
                  })}
              </ScrollableWrapper>
              <ButtonWrapper>
                {!isEditInProcess && !isAddInProcess ? (
                  <>
                    <StyledButton
                      colors={{
                        border: primary,
                        borderHover: primary,
                      }}
                      onClick={() => {
                        toggleView(null, null);
                        setValidatingIsStarted(false);
                      }}
                      isBlocked={blockButtons}
                    >
                      {languageStrings.closeButtonValue}
                    </StyledButton>
                    <ActionsWrapper>
                      {actionSet.map((item) => {
                        return item.type === ActionType.EDIT && accessData?.edit ? (
                          <StyledButton
                            onClick={() => toggleEdit()}
                            colors={{
                              main: black1,
                              background: pureWhite,
                              backgroundHover: primary,
                              border: primary,
                              borderHover: primary,
                            }}
                            isBlocked={blockButtons}
                          >
                            {languageStrings.insightDetailsDeviceConfigHeaderEdit}
                          </StyledButton>
                        ) : !!item.component ? (
                          <CustomButtonWrapper
                            onClick={() => {
                              handleCallback(item.callback);
                            }}
                          >
                            {item.component as any}
                          </CustomButtonWrapper>
                        ) : null;
                      })}
                      {allowDelete && accessData?.delete && (
                        <StyledButton
                          onClick={handleDelete}
                          colors={{
                            main: black1,
                            background: pureWhite,
                            backgroundHover: red2,
                            border: red2,
                            borderHover: red2,
                          }}
                          isBlocked={blockButtons}
                        >
                          {languageStrings.manageClientsTableHeaderDeleteButton}
                        </StyledButton>
                      )}
                    </ActionsWrapper>
                  </>
                ) : (
                  <>
                    <ActionsWrapper>
                      <StyledButton
                        onClick={() => {
                          handleRevert();
                          setValidatingIsStarted(false);
                        }}
                        colors={{
                          main: black1,
                          background: pureWhite,
                          border: primary,
                          borderHover: primary,
                          backgroundHover: primary,
                        }}
                        isBlocked={blockButtons}
                      >
                        {languageStrings.insightDetailsDeviceConfigurationEditInputsButtonsCancel}
                      </StyledButton>
                      <StyledButton
                        colors={{
                          border: primary,
                          borderHover: primary,
                        }}
                        onClick={() => {
                          handleSave();
                        }}
                        isBlocked={blockButtons || isSaveButtonDisabled}
                      >
                        {languageStrings.saveButton}
                      </StyledButton>
                    </ActionsWrapper>
                  </>
                )}
              </ButtonWrapper>
            </BodyWrapper>
          </Wrapper>
          {isUpdaitingInProcess && <Loader />}
        </StyledModalContainer>
      </StyledModal>
      <DeleteModal isOpenModal={isLocalDeleteInProcess || isDeleteInProcess}>
        <DeletePopupContainer padding="24px">
          <DeletePopup
            title={languageStrings.emphasizedHeaderTextModalTitle}
            semiTitle={languageStrings.manageDeleteSemiTitle}
            deletedElementsList={!isDeleteInProcess ? [localDeleteName] : nameList}
            handleOnClose={handleCloseDeleteModal}
            handleOnDelete={handleGlobalDelete}
            languageStrings={languageStrings}
          />
        </DeletePopupContainer>
      </DeleteModal>
      {markedArr.length > 0 && !isDeleteInProcess && (
        <MobilePortalContainer zIndex={'999999999'}>
          <MobileSelectActionWrapper>
            <MobileSelectActionBlock onClick={toggleDelete}>
              <MobileSelectActionButton type={TextType.TEXT_14_RED}>
                {languageStrings.manageClientsTableHeaderDeleteButton}
              </MobileSelectActionButton>
            </MobileSelectActionBlock>
            <MobileSelectActionBlock onClick={handleCancel}>
              <MobileSelectActionButton type={TextType.TEXT_14_GRAY}>
                {languageStrings.insightDetailsDeviceConfigurationEditInputsButtonsCancel}
              </MobileSelectActionButton>
            </MobileSelectActionBlock>
          </MobileSelectActionWrapper>
        </MobilePortalContainer>
      )}
    </>
  );
};
