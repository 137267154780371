import React, { FC, useState } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';

import { Text, TextType } from '../text/index';

const ExtraWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 0px 14px 0px;
`;

const Wrapper = styled.div`
  width: ${(props: any) => (props.width ? props.width : '315px')};
  height: 40px;
  position: relative;
  margin-top: 2px;
`;

const InputElement = styled.input`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: ${(props: any) => (props.disabled ? props.theme.colors.gray5 : props.theme.colors.white)};
  color: ${(props: any) => props.theme.colors.black};
  ::placeholder {
    font-family: 'Poppins', sans-serif;
    color: ${(props: any) => (props.disabled ? props.theme.colors.gray5 : props.theme.colors.gray2)};
  }
  font-family: 'Poppins', sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  border: 1px solid
    ${(props: any) =>
      props.disabled
        ? props.theme.colors.gray5
        : props.isFocused
        ? props.theme.colors.primary
        : props.isError
        ? props.theme.colors.red3
        : props.theme.colors.gray3};
  border-radius: ${(props: any) => props.theme.borderRadius.primary};
  padding: ${(props: any) => (props.image ? '8px 36px 8px 38px' : '8px 36px 8px 12px')};
  outline: none;
`;

const ImageWrapper = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  position: absolute;
  left: 10px;
  & > svg > path {
    stroke: ${(props: any) => props.theme.colors.black};
  }
`;

const AdditionalImage = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
`;

export interface InputProps {
  value: string;
  onChange: any;
  name: string;
  placeholder: string;

  onClick?: any;
  type?: string;
  image?: any;
  additionalImage?: any;
  additionalImageOnClick?: any;
  disabled?: boolean;
  width?: string;
  className?: string;
}

export const Input: FC<InputProps> = ({
  value,
  onChange,
  onClick,
  name,
  placeholder,
  image,
  type,
  width,
  additionalImage,
  additionalImageOnClick,
  disabled,
  ...props
}) => {
  const theme = useTheme();
  const [isFocused, setIsFocused] = useState(false);
  const { gray1, black } = theme.colors;

  return (
    <ExtraWrapper {...props}>
      <Text type={TextType.TEXT_12_BLACK} color={gray1} margin="0 0 2px 6px">
        {name}
      </Text>
      <Wrapper width={width}>
        {image ? (
          <ImageWrapper color={gray1} theme={theme}>
            {image}
          </ImageWrapper>
        ) : null}
        <InputElement
          type={type ? type : 'text'}
          onClick={onClick}
          value={value}
          placeholder={placeholder}
          theme={theme}
          color={black}
          isFocused={isFocused}
          isError={false}
          onFocus={() => {
            setIsFocused(true);
          }}
          onBlur={() => {
            setIsFocused(false);
          }}
          onChange={onChange}
          image={image}
          disabled={disabled}
        />
        {additionalImage ? (
          <AdditionalImage color={gray1} theme={theme} onClick={additionalImageOnClick}>
            {additionalImage}
          </AdditionalImage>
        ) : null}
      </Wrapper>
    </ExtraWrapper>
  );
};
