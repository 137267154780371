import React, { FC } from 'react';
import styled from '@cthings.co/styled-components';
import { AddCompanyIcons } from './AddCompanyIcons/AddCompanyIcons';
import { Text } from '@bit/first-scope.text';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { AddCompanyCardType } from '../../types';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid;
  border-color: ${colorFetch('primary')};
  border-radius: 6px;
  padding: 20px 16px;
  box-sizing: border-box;
  cursor: pointer;
  margin-top: 15px;
  &:hover {
    border: 2px solid;
    border-color: ${colorFetch('primary')};
    padding: 19px 15px;
  }
` as any;

const HeadingBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

// @ts-ignore
const Title = styled(Text)`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  color: ${colorFetch('black1')};
` as any; // @TODO fix type

// @ts-ignore
const MainInfo = styled(Text)`
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: ${colorFetch('gray1')};
  margin-top: 10px;
` as any; // @TODO fix type

export interface AddCompanyCardProps {
  title: string;
  info: string;
  type: AddCompanyCardType;
  onClick: (type: AddCompanyCardType) => void;
}

export const AddCompanyCard: FC<AddCompanyCardProps> = ({ title, info, type, onClick }) => {
  return (
    <Wrapper onClick={onClick(type)}>
      <HeadingBlock>
        <Title>{title}</Title>
        <AddCompanyIcons type={type} />
      </HeadingBlock>
      <MainInfo>{info}</MainInfo>
    </Wrapper>
  );
};
