import React, { FC, ReactNode, useCallback } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';

import { media } from '../media';
import { Text, TextType } from '../text/index';

import { DropletIcon } from './components/DropletIcon';
import { ThermometerIcon } from './components/ThermometerIcon';
import { CPUIcon } from './components/CPUIcon';
import { LocationIcon } from './components/LocationIcon';

export enum CardType {
  WATER_LEVEL = 'WATER_LEVEL',
  TEMPERATURE = 'TEMPERATURE',
  DEVICES = 'DEVICES',
  LOCATIONS = 'LOCATIONS',
  CITIES = 'CITIES',
}

const ExtraWrapper = styled.div`
  width: 280px;
  height: ${(props: any) => (props.type === CardType.CITIES ? '224px' : '120px')};
  box-sizing: border-box;
  box-shadow: ${(props: any) => props.theme.shadows.additionalShadow2};
  background-color: white;
  border: none;
  border-radius: ${(props: any) => props.theme.borderRadius.additional16};
  cursor: pointer;
  margin: ${(props: any) => (props.margin ? props.margin : '0')};
  padding: ${(props: any) => (props.type === CardType.CITIES ? '20px 24px 30px 24px' : '20px 24px')};

  ${media.tablet`
    width: 100%; 
  `}
`;

const Button = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > span {
    color: ${(props: any) => (props.type === CardType.CITIES ? props.color : props.titleColor)};
    font-size: ${(props: any) => (props.type === CardType.CITIES ? '14px' : '12px')};
    font-weight: ${(props: any) => (props.type === CardType.CITIES ? '700' : '500')};
    line-height: ${(props: any) => (props.type === CardType.CITIES ? '24px' : '16px')};
  }
`;

const Wrapper = styled.div`
  height: ${(props: any) => (props.type === CardType.CITIES ? 'max-content' : '64px')};
  display: ${(props: any) => (props.type === CardType.CITIES ? 'block' : 'flex')};
  justify-content: space-between;
  align-items: flex-end;

  & > svg > path {
    stroke: ${(props: any) => props.iconColor};
  }
`;

export interface DashboardCardProps {
  type: CardType;
  title?: string;
  data?: any[];
  margin?: string;
  count?: string | number;
  children?: ReactNode;
  languageStrings?: any;
}

export const DashboardCard: FC<DashboardCardProps> = ({
  type,
  count,
  title,
  margin,
  data,
  children,
  languageStrings,
  ...props
}) => {
  const theme = useTheme();
  const { black, gray2, primary } = theme.colors;
  const color = black;
  const titleColor = gray2;
  const iconColor = primary;
  const getTitle = useCallback(() => {
    if (title) return title;
    switch (type) {
      case CardType.DEVICES:
        return languageStrings && languageStrings.dashBoardCardDevicesTitle
          ? languageStrings.dashBoardCardDevicesTitle
          : 'Total Devices';
      case CardType.TEMPERATURE:
        return languageStrings && languageStrings.insightDetailsRealTimeCardsTemperature
          ? languageStrings.insightDetailsRealTimeCardsTemperature
          : 'Temperature';
      case CardType.CITIES:
        return languageStrings && languageStrings.dashBoardCardCitiesTitle
          ? languageStrings.dashBoardCardCitiesTitle
          : 'Locations';
      case CardType.LOCATIONS:
        return languageStrings && languageStrings.dashBoardCardLocationsTitle
          ? languageStrings.dashBoardCardLocationsTitle
          : 'Total Locations';
      case CardType.WATER_LEVEL:
        return languageStrings && languageStrings.dashBoardCardDistanceTitle
          ? languageStrings.dashBoardCardDistanceTitle
          : 'Distance to water';
    }
  }, [type, title, languageStrings]);
  return (
    <ExtraWrapper margin={margin} type={type} {...props} theme={theme}>
      <Button type={type} color={color} titleColor={titleColor}>
        <Text type={TextType.TITLE_H5} color={titleColor}>
          {getTitle()}
        </Text>
        <Wrapper iconColor={iconColor} type={type}>
          <Text type={TextType.PROBLEMS_BUTTON_TEXT} color={color}>
            {type === CardType.TEMPERATURE && `${count}`}
            {type === CardType.WATER_LEVEL && `${count}`}
            {type === CardType.DEVICES && count}
            {type === CardType.LOCATIONS && count}
          </Text>
          {type === CardType.WATER_LEVEL && <DropletIcon />}
          {type === CardType.TEMPERATURE && <ThermometerIcon />}
          {type === CardType.DEVICES && <CPUIcon />}
          {type === CardType.LOCATIONS && <LocationIcon />}
          {type === CardType.CITIES && <> {children} </>}
        </Wrapper>
      </Button>
    </ExtraWrapper>
  );
};
DashboardCard.defaultProps = {};
