import React, { ChangeEvent, ReactNode, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Text, TextType } from '@bit/first-scope.text';
import { selectLanguageStrings } from '../../app/state/user';
import { useMediaType } from '@cthings.co/styles-utils';
import { InputModal } from '../inputModal/InputModal';
import { ReactComponent as SearchIcon } from '../../assets/Search.svg';
import { AddButton } from './components/addButton/AddButton';
import { ActionWrapper, UpperWrapper, Wrapper, StyledText } from './styled';
import { ExportButton } from '../exportButton/ExportButton';
import { useTheme } from '@cthings.co/styled-components';
import { useTableContext } from '@cthings.co/universal-table';
import { useHistory } from '../../utils/react-router-dom-abstraction';
import { stringifyUrl } from 'query-string';
import { injectPathParams } from '@cthings.co/utils';
import { getPath } from '../../routes';

export enum ManageTableType {
  CLIENTS_TABLE = 'CLIENTS_TABLE',
  DEVICES_TABLE = 'DEVICES_TABLE',
  USERS_TABLE = 'USERS_TABLE',
  INCIDENTS_TABLE = 'INCIDENTS_TABLE',
  MEASUREMENT_TABLE = 'MEASUREMENT_TABLE',
}

export const ManageLayout = ({
  type,
  title,
  handleAdd,
  children,
  accessData,
  withSmartSearch,
  searchValue,
  setSearchValue,
  openExportModal,
  isDataLoaded,
  withHistoryReplace,
}: {
  type: ManageTableType;
  title: string | ReactNode;
  isDataLoaded?: boolean;
  handleAdd?: () => void;
  children: ReactNode;
  accessData?: { view: boolean; edit: boolean; delete: boolean };
  withSmartSearch?: boolean;
  searchValue?: string;
  setSearchValue?: (value: string) => void;
  openExportModal?: () => void;
  withHistoryReplace?: boolean;
}) => {
  const theme = useTheme();
  const languageStrings = useSelector(selectLanguageStrings);
  const [{ globalProps }] = useTableContext();
  const history = useHistory();

  const { tablet } = useMediaType();

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue && setSearchValue(e.currentTarget.value);
    withHistoryReplace &&
      history.replace(
        stringifyUrl({
          url: injectPathParams(getPath(globalProps.tablePath), globalProps.pathParams),
          query: { ...globalProps.queryParams, search_value: e.currentTarget.value },
        }),
      );
  };

  useEffect(() => {
    searchValue && setSearchValue && setSearchValue(searchValue);
  }, []);

  const addButtonCondition =
    accessData?.edit &&
    (type === ManageTableType.USERS_TABLE ||
      type === ManageTableType.MEASUREMENT_TABLE ||
      type === ManageTableType.INCIDENTS_TABLE);

  const exportButtonCondition = type === ManageTableType.DEVICES_TABLE || type === ManageTableType.USERS_TABLE;

  return (
    <Wrapper>
      <UpperWrapper theme={theme} withSmartSearch={withSmartSearch}>
        <StyledText type={TextType.TITLE_H1}>{title}</StyledText>
        <ActionWrapper>
          {exportButtonCondition && <ExportButton onClick={openExportModal} languageStrings={languageStrings} />}
          {addButtonCondition && isDataLoaded && <AddButton handleAdd={handleAdd} type={type} />}
        </ActionWrapper>
      </UpperWrapper>

      {withSmartSearch && !tablet && searchValue !== undefined && (
        <InputModal
          width={'536px'}
          margin={'0 0 25px'}
          value={searchValue}
          onChange={onInputChange}
          placeholder={languageStrings.whatAreYouLookingFor}
          image={<SearchIcon />}
        />
      )}
      {children as any}
    </Wrapper>
  );
};
