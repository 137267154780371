import React, { useCallback, ReactNode, useEffect, useState } from 'react';
import styled from '@cthings.co/styled-components';
import ReactTooltip from 'react-tooltip';
import { useTooltipRequestMonitor, useTooltip } from './functions';
import { usePrevious } from '../../utils/usePrevious';
import { media } from '@cthings.co/styles-utils';
import { useTheme } from '@cthings.co/styled-components';

const Div = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  margin: 0;
  box-sizing: border-box;
`;

type StyledTooltipProps = {
  width?: string;
  borderRadius: string;
  styledColor?: string;
};

const StyledTooltip = styled(ReactTooltip as any)<StyledTooltipProps>`
  white-space: break-spaces;
  word-wrap: break-word;
  text-align: center;
  max-width: ${({ width }) => width || '150px'};
  width: 100%;
  opacity: 1 !important;
  border-radius: ${({ borderRadius }) => borderRadius};
  &.coloured {
    background-color: ${({ styledColor }) => styledColor};
  }
  &.top {
    &.coloured:after {
      border-top-color: ${({ styledColor }) => styledColor} !important;
    }
  }
  &.bottom {
    &.coloured:after {
      border-bottom-color: ${({ styledColor }) => styledColor} !important;
    }
  }
  &.right {
    &.coloured:after {
      border-right-color: ${({ styledColor }) => styledColor} !important;
    }
  }
  &.left {
    &.coloured:after {
      border-left-color: ${({ styledColor }) => styledColor} !important;
    }
  }
  ${media.semiTablet} {
    display: inline-block;
  }
`;

export enum TooltipTriggerType {
  HOVER = 'HOVER',
  CLICK = 'CLICK',
  STATIC = 'STATIC',
}

export enum TooltipPlace {
  TOP = 'top',
  RIGHT = 'right',
  LEFT = 'left',
  BOTTOM = 'bottom',
}

export const constructTooltip = (children: ReactNode, config: any) => {
  return (
    <Tooltip id={config.tooltipId} text={config.tooltipText}>
      {children}
    </Tooltip>
  );
};

const Tooltip = ({
  id,
  text,
  children,
  triggerType = TooltipTriggerType.HOVER,
  color,
  place = TooltipPlace.TOP,
  width,
  disabled,
  ...props
}: {
  id: string;
  text: string;
  children: ReactNode;
  triggerType?: TooltipTriggerType;
  color?: string;
  place?: TooltipPlace;
  disabled?: boolean;
  className?: string;
  width?: string;
}) => {
  const [tooltipCurrent, setTooltipCurrent] = useState<any>(null);
  const theme = useTheme();
  const { black1 } = theme.colors;
  const { primary } = theme.borderRadius;
  //const tooltipRef = useRef(null);

  const tooltipRef = useCallback((node: any) => {
    if (node !== null) {
      setTooltipCurrent(node);
    }
  }, []);

  const showTooltip = useCallback(() => {
    if (tooltipCurrent) {
      ReactTooltip.show(tooltipCurrent);
    }
  }, [tooltipCurrent]);
  const hideTooltip = useCallback(() => {
    if (tooltipCurrent) {
      ReactTooltip.hide(tooltipCurrent);
    }
  }, [tooltipCurrent]);

  const [requestedToOpen, requestedToClose] = useTooltipRequestMonitor(id);
  const prevRequestedToOpen = usePrevious(requestedToOpen);
  const prevRequestedToClose = usePrevious(requestedToClose);

  useEffect(() => {
    triggerType === TooltipTriggerType.STATIC && showTooltip && showTooltip();
  }, [triggerType, showTooltip]);

  useEffect(() => {
    requestedToOpen && !prevRequestedToOpen && showTooltip();
  }, [requestedToOpen, prevRequestedToOpen]);

  useEffect(() => {
    requestedToClose && !prevRequestedToClose && hideTooltip();
  }, [requestedToClose, prevRequestedToClose]);

  const dataEventKey = 'data-event';
  const dataEventOffKey = 'data-event-off';
  const globalEventOffKey = 'globalEventOff';

  const getConfig = useCallback(() => {
    switch (triggerType) {
      case TooltipTriggerType.STATIC:
        return {
          wrapperConfig: { [dataEventKey]: 'dblclick' },
          tooltipConfig: {},
        };
      case TooltipTriggerType.CLICK:
        return {
          wrapperConfig: { [dataEventKey]: 'click focus' },
          tooltipConfig: { [globalEventOffKey]: 'click' },
        };
      case TooltipTriggerType.HOVER:
        return {
          wrapperConfig: {},
          tooltipConfig: {},
        };
    }
  }, [triggerType]);

  const config = getConfig();

  return (
    <>
      {!disabled && id ? (
        <>
          <Div ref={tooltipRef} data-tip="tooltip" data-for={id} data-place={place} {...config.wrapperConfig}>
            {children as any}
          </Div>
          <StyledTooltip
            effect="solid"
            backgroundColor={black1}
            id={id}
            {...config.tooltipConfig}
            className={color ? `coloured ${place}` : ''}
            styledColor={color}
            borderRadius={primary}
            width={width}
            {...props}
          >
            {text}
          </StyledTooltip>
        </>
      ) : (
        <Div>{children as any}</Div>
      )}
    </>
  );
};

export default Tooltip;
export { useTooltip };
