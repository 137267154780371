import React, { ReactNode, useEffect, useState } from 'react';
import styled from '@cthings.co/styled-components';
import { Modal } from '@bit/first-scope.modal';
import { ModalContainer } from '@bit/first-scope.modal-container';
import { media } from '@cthings.co/styles-utils';
import { useWindowSize } from '@cthings.co/styles-utils';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

type StyledModalProps = {
  isSmaller: boolean;
};

// @TODO fix media types
// @ts-ignore
const StyledModal = styled(Modal)<StyledModalProps>`
  width: 100%;
  z-index: var(--modal-first-layer);
  & > div {
    width: 100%;
  }

  ${media.semiTablet} {
    align-items: ${({ isSmaller }: any) => (isSmaller ? 'flex-start' : 'flex-end')};
  }
`;

// @ts-ignore
const StyledModalContainer = styled(ModalContainer)`
  width: 100% !important;
  max-width: 566px !important;
  box-sizing: border-box;
  padding: 25px !important;
  ${media.semiTablet} {
    border-radius: 12px 12px 0 0;
    height: 100%;
    align-items: flex-end;
  }
` as any; // @TODO fix type

type CustomSrollFieldProps = {
  maxHeight: string;
};

const CustomSrollField = styled.div<CustomSrollFieldProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: ${({ maxHeight }) => maxHeight};
  overflow: auto;
  overscroll-behavior-y: contain;
  &::-webkit-scrollbar {
    width: 4px;
    background: ${colorFetch('gray4')};
    opacity: 1;
    border-radius: ${({ theme }) => theme.borderRadius.primary};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${colorFetch('gray3')};
    border-radius: ${({ theme }) => theme.borderRadius.primary};
    position: relative;
    top: 4px;
    right: 14px;
  }
`;

export const CustomModal = ({ isOpen, children, ...props }: { isOpen: boolean; children: ReactNode; props?: any }) => {
  const [modalHeight, setModalHeight] = useState(0);
  const screenHeight = useWindowSize()[1];
  const maxHeight = screenHeight - 50 + 'px';
  const isSmallerSreenHeight = screenHeight < modalHeight;

  useEffect(() => {
    const modalHeieight = document.getElementById('inModal');
    setModalHeight(modalHeieight?.offsetHeight as any);
  }, [isOpen]);

  return (
    <StyledModal isOpenModal={isOpen} isSmaller={isSmallerSreenHeight} {...props}>
      <StyledModalContainer id="inModal">
        {/* @ts-ignore */}
        <CustomSrollField maxHeight={maxHeight}>{children}</CustomSrollField>
      </StyledModalContainer>
    </StyledModal>
  );
};
