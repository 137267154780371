import React from 'react';

export const CloseImage = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 6L12 11.999L18 6L6 18L12.0005 12L18 18"
        stroke="#555770"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
