import React from 'react';

export const SearchImage = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.125 17.25C12.7418 17.25 14.4441 16.5546 15.5 15.5L19 19L15.5 15.5C16.5215 14.4953 17.25 12.6061 17.25 11.125C17.25 7.74226 14.5077 5 11.125 5C7.74226 5 5 7.74226 5 11.125C5 14.5077 7.74226 17.25 11.125 17.25Z"
        stroke="#136B2F"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
