import React, { useMemo } from 'react';
import styled from '@cthings.co/styled-components';
import { Modal } from '@bit/first-scope.modal';
import { ModalContainer } from '@bit/first-scope.modal-container';
import { Text, TextType } from '@bit/first-scope.text';
import { CommonButton } from '@cthings.co/buttons';
import { media } from '@cthings.co/styles-utils';
import { CloseButtonRound } from '../../../../containers/common/reports/components/closeButtonRound/CloseButtonRound';
import { Dropdown } from '../../../dropdown/Dropdown';
import { TableInput, TableInputType } from '../../../tableInput/TableInput';
import { useBlockBodyScroll } from '../../../../utils/useBlockBodyScroll';
import { useTheme } from '@cthings.co/styled-components';
import { getEndDate, getStartDate } from '../header/EventsHeader';

// @ts-ignore
const StyledModal = styled(Modal)`
  ${media.semiTablet} {
    align-items: flex-end;
    & > div {
      width: 100%;
      align-items: flex-end;
    }
  }
` as any; // @TODO fix commponent type

// @ts-ignore
const StyledContainer = styled(ModalContainer)`
  width: 528px;
  align-items: flex-start;
  padding: 24px;
  ${media.semiTablet} {
    width: 100%;
    border-radius: 24px 24px 0 0;
    padding: 16px;
  }
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

// @ts-ignore
const Title = styled(Text)`
  font-size: 18px;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 22px;
`;

const StyledDropdown = styled(Dropdown)`
  margin-bottom: 10px;
  & > span {
    visibility: visible !important;
  }
  & > div {
    width: 100%;
    height: 40px;
  }
`;

type DateRangeProps = {
  isDateAvailable: boolean;
  color: string;
};

const DateRange = styled(TableInput)<DateRangeProps>`
  pointer-events: ${({ isDateAvailable }) => (isDateAvailable ? 'auto' : 'none')};
  & > div > div {
    width: calc(50% - 8px);
    & > div {
      width: 100%;
      & > div > div > div {
        height: 40px;
        & > div > svg > path {
          stroke: ${({ color }) => color};
        }
        & > span {
          color: ${({ color }) => color};
          font-weight: 400 !important;
        }
      }
    }
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 14px;
`;

type ButtonProps = {
  isApplyAvailable: boolean;
};

// @ts-ignore
const Button = styled(CommonButton)<ButtonProps>`
  width: 100%;
  height: 40px;
  &.first {
    margin: 0 0 10px 0;
    pointer-events: ${({ isApplyAvailable }) => (isApplyAvailable ? 'auto' : 'none')};
    user-select: ${({ isApplyAvailable }) => (isApplyAvailable ? 'auto' : 'none')};
    cursor: ${({ isApplyAvailable }) => (isApplyAvailable ? 'pointer' : 'auto')};
  }
`;

export const FiltersEventsModal = ({
  isOpenModal,
  closeModal,
  handleApply,
  filters,
  setCurrentFilters,
  setFilters,
  data,
  applyingStatus,
  isApplyButtonActive,
  languageStrings,
}: {
  isOpenModal?: boolean;
  closeModal: () => void;
  handleApply: () => void;
  filters: any;
  setCurrentFilters: any;
  setFilters: (filterName: string, value: { tooltip: string; id: string; key: string }) => void;
  data: { ts_from: Date; ts_to: Date };
  applyingStatus: number;
  isApplyButtonActive: boolean;
  languageStrings?: any;
}) => {
  const theme = useTheme();
  const { primary, secondary1, pureWhite, black, gray1, gray3 } = theme.colors as any; // @TODO fix theme type
  const filterErrorMessage = languageStrings.filtersSelectError;

  const today = new Date();

  const period = !!filters?.periods?.default_value ? filters?.periods?.default_value : filters?.periods?.items_list[3];
  const periodListRedux = filters?.periods?.items_list;
  const isApplyButtonAvailable = isApplyButtonActive && !!period;

  useBlockBodyScroll(!!isOpenModal);

  const findKey = (arr: any[], id: string) => arr.find((item: any) => item.id === id).key;

  const applyButtonText =
    applyingStatus === 0
      ? languageStrings.applyButton
      : applyingStatus === 1
      ? languageStrings.applyButton1
      : languageStrings.applyButton2;

  const periodOnChangeArr = useMemo(
    () => [
      () => {
        setCurrentFilters((filters: any) => ({
          ...filters,
          ts_from: getStartDate(new Date(new Date().setDate(new Date().getDate() - 7))),
          ts_to: getEndDate(new Date()),
        }));
      },
      () => {
        setCurrentFilters((filters: any) => ({
          ...filters,
          ts_from: getStartDate(new Date(new Date().setMonth(new Date().getMonth() - 1))),
          ts_to: getEndDate(new Date()),
        }));
      },
      () => {
        setCurrentFilters((filters: any) => ({
          ...filters,
          ts_from: getStartDate(new Date(new Date().setMonth(new Date().getMonth() - 6))),
          ts_to: getEndDate(new Date()),
        }));
      },
    ],
    [setCurrentFilters],
  );

  const periodList = periodListRedux.map((item: any, index: number, arr: any[]) =>
    index !== arr.length - 1
      ? { name: item.tooltip, id: item.id, key: item.key, onClick: periodOnChangeArr[index] }
      : { name: item.tooltip, id: item.id, key: item.key },
  );

  const handleAppleOnClick = () => {
    handleApply();
  };

  const dropdowns = [
    {
      value: period,
      valueList: periodList,
      fieldName: languageStrings.dataPeriodLabel,
      onChange: (value: { tooltip: string; id: string; key: string }) => {
        setFilters('periods', value);
      },
    },
  ];

  const buttons = [
    {
      value: applyButtonText,
      backgroundColor: isApplyButtonAvailable ? primary : gray3,
      backgroundColorHover: isApplyButtonAvailable ? secondary1 : gray3,
      borderColor: isApplyButtonAvailable ? primary : gray3,
      borderColorHover: isApplyButtonAvailable ? secondary1 : gray3,
      color: pureWhite,
      onClick: handleAppleOnClick,
    },
    {
      value: languageStrings.insightDetailsDeviceConfigurationEditInputsButtonsCancel,
      backgroundColor: pureWhite,
      borderColor: primary,
      color: black,
      onClick: closeModal,
    },
  ];

  return (
    <StyledModal isOpenModal={isOpenModal}>
      <StyledContainer>
        <TitleWrapper>
          <Title type={TextType.TITLE_PRIMARY}>{languageStrings.filtersButton}</Title>
          <CloseButtonRound closeOnClick={closeModal} />
        </TitleWrapper>
        <Wrapper>
          {dropdowns.map((item: any) => {
            const { value, valueList, fieldName, onChange } = item || {};
            const { id, tooltip, key } = value || {};

            return (
              <StyledDropdown
                key={id}
                // @TODO check value arguments
                value={{ name: tooltip ? tooltip : filterErrorMessage, id /*, key*/ }}
                valuesList={valueList}
                fieldName={fieldName}
                onChange={(value: { name: string; id: string }) => {
                  const key: string = findKey(valueList, value.id);
                  onChange({ tooltip: value.name, id: value.id, key });
                }}
                placeholder={fieldName}
                languageStrings={languageStrings}
              />
            );
          })}
          <DateRange
            type={TableInputType.DATE_RANGE_WITH_ICON}
            value={{
              startDate: new Date(filters?.ts_from),
              endDate: new Date(filters?.ts_to),
            }}
            handleChangeValue={{
              setStartDate: (value: any) => {
                setFilters('periods', { tooltip: periodList[3].name, id: periodList[3].id, key: periodList[3].key });
                setCurrentFilters((filters: any) => ({ ...filters, ts_from: getStartDate(value) }));
              },
              setEndDate: (value: any) => {
                setFilters('periods', { tooltip: periodList[3].name, id: periodList[3].id, key: periodList[3].key });
                setCurrentFilters((filters: any) => ({ ...filters, ts_to: getEndDate(value) }));
              },
            }}
            options={{
              title: languageStrings.selectedDatesLabel,
              startOptions: {
                minValue: null,
                maxValue: new Date(new Date(data.ts_to).getTime() - 7 * 24 * 60 * 60 * 1000),
              },
              endOptions: {
                minValue: null,
                maxValue: new Date(),
              },
              bottom: '434px',
            }}
            isDateAvailable={!!period}
            color={!!period ? gray1 : gray3}
            isEditInProcess
          />
        </Wrapper>
        <ButtonWrapper>
          {buttons.map((item: any, index: number) => {
            const {
              value,
              onClick,
              backgroundColor,
              backgroundColorHover,
              borderColor,
              borderColorHover,
              color,
            } = item;
            return (
              <Button
                className={index === 0 ? 'first' : ''}
                onClick={onClick}
                colors={{
                  main: color,
                  background: backgroundColor,
                  backgroundHover: backgroundColorHover,
                  border: borderColor,
                  borderHover: borderColorHover,
                }}
                isApplyAvailable={isApplyButtonAvailable}
              >
                {value}
              </Button>
            );
          })}
        </ButtonWrapper>
      </StyledContainer>
    </StyledModal>
  );
};
