import React, { FC } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: 137px;
  background: linear-gradient(
    180deg,
    rgba(242, 242, 245, 0) 0%,
    ${(props: any) => props.theme.colors.gray5} 62.5%,
    ${(props: any) => props.theme.colors.gray5} 100%
  );
  position: absolute;
  bottom: 0px;
  z-index: 100;
  pointer-events: none;
`;

export interface OverlayGradientProps {
  className?: string;
}

export const OverlayGradient: FC<OverlayGradientProps> = ({ ...props }) => {
  const theme = useTheme();
  return <Wrapper theme={theme} {...props}></Wrapper>;
};
