import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from '@cthings.co/router-dom';
import { useEventsGraphHistory } from '../../../../../../../../../../api/devices/historyInsight';
import { selectLanguageStrings } from '../../../../../../../../../../app/state/user';
import { GraphType } from '../../../../../../../../../../components/graph/types';
import { useNotifierFunctions } from '../../../../../../../../../../features/notifier2';
import { StyledEventsGraph } from '../../../../../common/styledComponents/StyledComponents';
import { DefaultValue, EventsGraphFilters } from '../../../../../../insightsDetails';

interface EventsGraphProps {
  defaultFilters: EventsGraphFilters;
  isSmartCapDevice?: boolean;
}

export const EventsGraph: FC<EventsGraphProps> = ({ defaultFilters, isSmartCapDevice }) => {
  const languageStrings = useSelector(selectLanguageStrings);
  const { addNotification } = useNotifierFunctions();

  const params = useParams();
  const { id } = params;

  const keyword = 'ManholeDetailsEventsGraph';

  const [filtersAreLoaded, setFiltersAreLoaded] = useState<boolean>(false);

  const [currentFilters, setCurrentFilters] = useState(defaultFilters);
  const [savedFilters, setSavedFilters] = useState(defaultFilters);

  useEffect(() => {
    setSavedFilters(defaultFilters);
    setCurrentFilters(defaultFilters);
    setFiltersAreLoaded(defaultFilters.isLoaded);
  }, [defaultFilters.isLoaded]);

  const [applyingStatus, setApplyingStatus] = useState(0);

  const handleSuccess = (data: any) => {
    setApplyingStatus(2);
  };

  const handleError = (e: any) => {
    addNotification({ message: languageStrings.unexpectedErrorHasOccurred, type: 'error' });
    setApplyingStatus(0);
  };

  const { data: dataEventsGraph, isLoading } = useEventsGraphHistory({
    id: id || '',
    body: {
      filter_data_period: savedFilters.periods.default_value?.key || null,
      ts_from: savedFilters.ts_from,
      ts_to: savedFilters.ts_to,
    },
    handleSuccess,
    handleError,
    condition: filtersAreLoaded,
    triggers: [savedFilters],
    keywords: [keyword],
  });

  const handleChangeCurrentFilters = (fieldName: 'periods', value: DefaultValue) => {
    const newCurrentFilters: EventsGraphFilters = { ...currentFilters };

    newCurrentFilters[fieldName] = {
      ...newCurrentFilters[fieldName],
      default_value: value,
    };

    setCurrentFilters(newCurrentFilters);
  };

  const handleApplyFilters = () => {
    setApplyingStatus(1);
    setSavedFilters(currentFilters);
  };

  return (
    <StyledEventsGraph
      header={dataEventsGraph?.axis_labels?.title || 'Events over time'}
      placeholderProps={{
        keyword,
        style: { width: '100%', margin: '0 0 45px 0' },
      }}
      filters={currentFilters}
      savedFilters={savedFilters}
      setCurrentFilters={setCurrentFilters}
      data={dataEventsGraph}
      labels={{ yAxis: '(h)', xAxis: '' }}
      type={GraphType.EVENTS}
      handleChangeCurrentFilters={handleChangeCurrentFilters}
      applyOnClick={handleApplyFilters}
      applyingStatus={applyingStatus}
      setApplyingStatus={setApplyingStatus}
      isLoadingInProcess={isLoading}
      isSmartCapDevice={isSmartCapDevice}
    />
  );
};
