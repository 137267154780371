import React, { FC } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';

import { Modal } from '../modal/Modal';
import { SearchInput } from '../search-input';
import { Text, TextType } from '../text/Text';

import { SearchImage } from './components/SearchImage';

const Wrapper = styled.div`
  width: 343px;
  min-width: 343px;
  box-sizing: border-box;
  background-color: white;
  border-radius: ${(props: any) => props.theme.borderRadius.additional14};
  box-shadow: ${(props: any) => props.theme.shadows.additionalShadow3};
  padding: 8px;
`;

const SearchWrapper = styled.div`
  min-height: 104px;
  box-sizing: border-box;
  padding: 20px 12px 12px 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > svg > path {
    stroke: ${(props: any) => props.colorOfSpan};
  }
`;

const LocationRow = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
  padding: 0;
  text-align: left;
  width: 100%;
`;

export interface SearchBarModalProps {
  isOpenModal: boolean;
  setIsOpenModal?: any;
  className?: string;
  location?: string;
  languageStrings?: any;
  handleLocationChange?: any;
  locations: any[];
  setSelectedPos: any;
}

export const SearchBarModal: FC<SearchBarModalProps> = ({
  isOpenModal,
  setIsOpenModal,
  location,
  handleLocationChange,
  locations,
  setSelectedPos,
  languageStrings,
  ...props
}) => {
  const theme = useTheme();
  const { gray2 } = theme.colors;

  const handleLocationClick = (location: any) => {
    setSelectedPos(location);
    setIsOpenModal(false);
  };

  const handleClose = () => {
    setSelectedPos(null);
    setIsOpenModal(false);
  };

  return isOpenModal ? (
    <Modal isOpenModal={isOpenModal} {...props}>
      <Wrapper theme={theme}>
        <SearchInput action={handleClose} value={location || ''} onChange={handleLocationChange} />
        <SearchWrapper colorOfSpan={gray2}>
          {locations.length > 0 ? (
            <>
              {locations.map((location: any) => (
                <LocationRow onClick={() => handleLocationClick(location)}>{location.name}</LocationRow>
              ))}
            </>
          ) : (
            <>
              <SearchImage />
              <Text type={TextType.TEXT_14_GRAY} color={gray2}>
                {languageStrings && languageStrings.insightPlaceholder
                  ? languageStrings.insightPlaceholder
                  : 'Search for something...'}
              </Text>
            </>
          )}
        </SearchWrapper>
      </Wrapper>
    </Modal>
  ) : null;
};

SearchBarModal.defaultProps = {
  isOpenModal: false,
};
