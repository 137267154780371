export enum SizesType {
  megaMonitor = 'megaMonitor',
  ultramonitor = 'ultramonitor',
  wideMonitor = 'wideMonitor',
  monitor = 'monitor',
  desktop = 'desktop',
  tablet = 'tablet',
  phone = 'phone',
  phoneS = 'phoneS',
  phoneXS = 'phoneXS',
}

export type Sizes = {
  [key in SizesType]: number;
};

export type Media = {
  [key in SizesType]: boolean;
};

export const sizes: Sizes = {
  megaMonitor: 2800,
  ultramonitor: 1920,
  wideMonitor: 1680,
  monitor: 1440,
  desktop: 1024,
  tablet: 769,
  phone: 426,
  phoneS: 376,
  phoneXS: 321,
};

export const mediaType: Media = Object.keys(sizes as Sizes).reduce((acc: any, label: string) => {
  acc[label] = window.matchMedia(`(max-width: ${(sizes as Sizes)[label as SizesType]}px)`).matches;
  return acc;
}, {});

export type StyleState = {
  mediaType: typeof mediaType;
};
