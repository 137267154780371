import React, { FC, useState } from 'react';
// @ts-ignore
import styled, { ThemeProvider, useTheme } from '@xstyled/styled-components';
import { motion } from 'framer-motion';

const Container = styled.div`
  width: auto;
  height: auto;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Button = styled(motion.div)`
  width: 35px;
  height: 20px;
  padding-left: 2px;
  padding-right: 2px;
  border-radius: ${(props: any) => props.theme.borderRadius.additional10};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InnerCircle = styled(motion.div)`
  width: 16px;
  height: 16px;
  display: flex;
  border-radius: ${(props: any) => props.theme.borderRadius.round};
  box-shadow: ${(props: any) => props.theme.shadows.additionalShadow6};
  background-color: white;
`;

const LabelContainer = styled(motion.div)`
  width: auto;
  height: auto;
  padding: 2px;
  display: flex;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 12px;
  font-style: normal;
  margin: 0px 8px;
`;

export interface ToggleButtonProps {
  toggle: boolean;
  disabled: boolean;
  labelRight: string;
  labelLeft: string;
  handleToggle: (event: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
}

export const ToggleButton: FC<ToggleButtonProps> = ({
  handleToggle,
  toggle = false,
  labelRight = '',
  labelLeft = '',
  disabled = false,
  ...props
}) => {
  const [toggled, setToggle] = useState(toggle);
  const [disabledState] = useState(disabled);
  const theme = useTheme();
  const { primary } = theme.colors;

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setToggle(!toggled);
    handleToggle(e);
  };

  return (
    <ThemeProvider theme={theme} {...props}>
      <Container>
        <LabelContainer
          animate={{
            fontWeight: toggled ? '400' : '500',
            opacity: disabledState ? 0.3 : 1,
          }}
        >
          {labelLeft}
        </LabelContainer>
        <Button
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            disabled ? e.preventDefault() : handleClick(e);
          }}
          animate={{
            backgroundColor: primary,
            opacity: disabledState ? 0.3 : 1,
            justifyContent: toggled ? 'flex-end' : 'flex-start',
          }}
          transition={{ duration: 0.4, ease: 'linear' }}
          theme={theme}
        >
          <InnerCircle transition={{ duration: 0.4, ease: 'linear' }} theme={theme}></InnerCircle>
        </Button>
        <LabelContainer
          animate={{
            fontWeight: !toggled ? '400' : '500',
            opacity: disabledState ? 0.3 : 1,
          }}
        >
          {labelRight}
        </LabelContainer>
      </Container>{' '}
    </ThemeProvider>
  );
};

function handleToggle() {
  console.log('Toggled');
}

ToggleButton.defaultProps = {
  toggle: false,
  labelRight: 'List View',
  labelLeft: 'Map View',
  disabled: false,
  handleToggle: handleToggle,
};
