import React, { FC, useEffect, useState } from 'react';
// @ts-ignore
import { useTheme } from '@xstyled/styled-components';
import { StyledGraph } from '../../../../../common/styledComponents/StyledComponents';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../../../../../../app/state/user';
import { useParams } from '@cthings.co/router-dom';
import { GraphSensorType } from '../types';
import { InsightDataType } from '../../../../../../../../../../enums/InsightDataType';
import { TypeGraph } from '../../../../../../../../../../enums/GraphType';
import { useNotifierFunctions } from '../../../../../../../../../../features/notifier2';
import { useEntityHistory } from '../../../../../../../../../../api/devices/historyInsight';
import { GraphType } from '../../../../../../../../../../components/graph/types';
import { DefaultFilters, DefaultValue, FilterFieldNames, GraphFilters } from '../../../../../InsightsDetails';

interface TemperatureGraphProps {
  defaultFilters: DefaultFilters['temperature'];
}

export const TemperatureGraph: FC<TemperatureGraphProps> = ({ defaultFilters }) => {
  const theme = useTheme();
  const { orange3 } = theme.colors;

  const languageStrings = useSelector(selectLanguageStrings);
  const { addNotification } = useNotifierFunctions();

  const params = useParams();
  const { id } = params;

  const keyword = 'ManholeDetailsGraphTemperature';

  const [filtersAreLoaded, setFiltersAreLoaded] = useState<boolean>(false);

  const [currentFilters, setCurrentFilters] = useState(defaultFilters);
  const [savedFilters, setSavedFilters] = useState(defaultFilters);

  useEffect(() => {
    setSavedFilters(defaultFilters);
    setCurrentFilters(defaultFilters);
    setFiltersAreLoaded(defaultFilters.isLoaded);
  }, [defaultFilters.isLoaded]);

  const [applyingStatus, setApplyingStatus] = useState(0);

  const handleSuccess = (data: any) => {
    setApplyingStatus(2);
  };

  const handleError = (e: any) => {
    addNotification({ message: languageStrings.unexpectedErrorHasOccurred, type: 'error' });
    setApplyingStatus(0);
  };

  const { data: dataHistory, isLoading } = useEntityHistory({
    id: id || '',
    body: {
      sensor: GraphSensorType.TEMPERATURE,
      filter_data_period: savedFilters.periods.default_value?.key || '',
      filter_interval: savedFilters.intervals.default_value?.key || '',
      filter_averaging_method: savedFilters.averaging_methods.default_value?.key || '',
      ts_from: savedFilters.ts_from,
      ts_to: savedFilters.ts_to,
    },
    handleSuccess,
    handleError,
    condition: filtersAreLoaded,
    triggers: [savedFilters],
    keywords: [keyword],
  });

  const { axis_labels, axis_limits, data, calibration_periods, threshold_data, unit } = dataHistory;

  const payload = {
    sensor: InsightDataType.TEMPERATURE,
    id: TypeGraph.TEMPERATURE,
    color: orange3,
    data: data.map((item: any) => ({ y: item.measurement, x: item.ts })),
    calibrationData: calibration_periods || [],
    thresholdData: threshold_data || [],
    header: axis_labels?.title || '',
    invertAxis: !axis_limits.y || axis_limits.y.max < axis_limits.y.min,
    device_id: id,
    unit: unit,
  };

  const handleChangeCurrentFilters = (fieldName: FilterFieldNames, value: DefaultValue) => {
    const newCurrentFilters: GraphFilters = { ...currentFilters };

    newCurrentFilters[fieldName] = {
      ...newCurrentFilters[fieldName],
      default_value: value,
    };

    setCurrentFilters(newCurrentFilters);
  };

  const handleApplyFilters = () => {
    setApplyingStatus(1);
    setSavedFilters(currentFilters);
  };

  return (
    <StyledGraph
      placeholderProps={{
        keyword,
        style: { width: '100%', margin: '0' },
      }}
      labels={{ yAxis: `(${unit || '°C'})` }}
      data={payload}
      header={`${languageStrings.insightDetailsRealTimeCardsTemperature}`}
      filters={currentFilters}
      setCurrentFilters={setCurrentFilters}
      type={GraphType.TEMPERATURE}
      handleChangeCurrentFilters={handleChangeCurrentFilters}
      applyOnClick={handleApplyFilters}
      applyingStatus={applyingStatus}
      setApplyingStatus={setApplyingStatus}
      isLoadingInProcess={isLoading}
      limits={axis_limits}
    />
  );
};
