import React, { FC } from 'react';

// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';

//import Tooltip, { useTooltip } from '../tooltip/Tooltip';

import { EditImage } from './components/EditImage';

const Button = styled.div`
  display: flex;
  align-items: center;
  cursor: ${(props: any) => (props.editInProcess ? 'auto' : 'pointer')};
  & > svg > path {
    stroke: ${(props: any) => (props.color ? props.color : props.theme.colors.gray3)};
    transition: stroke 0.3s ease;
  }
  &:hover > svg > path {
    stroke: ${(props: any) => (props.colorHover ? props.colorHover : props.theme.colors.gray2)};
  }
`;

export interface EditButtonProps {
  tooltipText: string;
  color?: string;
  colorHover?: string;
  onClick: () => void;
  className?: string;
  editInProcess?: boolean;
}

export const EditButton: FC<EditButtonProps> = ({
  editInProcess,
  color,
  colorHover,
  onClick,
  tooltipText,
  ...props
}) => {
  const theme = useTheme();
  //const [tooltipIds, , hideTooltip] = useTooltip();
  const handleClick = () => {
    //hideTooltip(tooltipIds[0]);
    onClick && onClick();
  };

  return (
    <Button
      onClick={handleClick}
      color={color}
      colorHover={editInProcess ? color : colorHover}
      theme={theme}
      editInProcess={editInProcess}
      {...props}
    >
      <EditImage />
      {/*<Tooltip
        type={TooltipType.ARROW_BOTTOM}
        text={tooltipText}
        bottom={'20px'}
        right={'-46.5px'}   
        visibility={clicked}
      />
       {editInProcess ? (
        <EditImage /> 
    ) : (
        <Tooltip id={tooltipIds[0]} text={tooltipText}>
          <EditImage />
        </Tooltip>
      )}
    */}
    </Button>
  );
};

export default EditButton;
