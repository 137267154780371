import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { media } from '../media';
import { CloseButton } from '../close-button';
import { LocationImage } from './components/LocationImage';

export const ModalSearch = styled.div`
  position: absolute;
  z-index: 9999999999;
  bottom: -8px;
  left: 0;
  right: 0;
  transform: translateY(100%);
  background-color: white;
  box-shadow: ${(props: any) => props.theme.shadows.additionalShadow4};
  border-radius: ${(props: any) => props.theme.borderRadius.primary};
  min-height: 100px;
  max-height: 270px;
  padding: 20px 8px 20px 20px;
  box-sizing: border-box;
`;
const Button = styled.button`
  width: ${(props: any) => (props.isOpen ? '205px' : '43px')};
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: primary;
  outline: none;
  border: none;
  border-radius: ${(props: any) => `0 ${props.theme.borderRadius.primary} ${props.theme.borderRadius.primary} 0`};
  cursor: pointer;
  padding: 6px 8px 6px 7px;
  transition: all 0.3s ease;
  & > svg {
    width: 28px;
    height: 28px;
  }
  &:hover {
    background-color: secondary2;
  }
  & > svg > path {
    transition: all 0.3s ease;
    stroke: ${(props: any) => props.theme.colors.white};
  }
  &:hover > svg > path {
    transition: fill 0.3s ease;
    fill: ${(props: any) => props.theme.colors.secondary2};
  }
  ${media.tablet`
  width: 43px;
  & > svg > path {
    fill: ${(props: any) => (props.isOpen ? props.theme.colors.white : props.theme.colors.primary)};
  }
  & > svg > path:nth-child(2n) {
    fill: ${(props: any) => props.theme.colors.primary};
  }
  `}
`;
const LocationWrapper = styled.div`
  width: 164px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 0 5px;
  ${media.tablet`
    display: none;
  `}
`;
const InputLocation = styled.input`
  color: white;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
  padding: 0;
  background-color: transparent;
  width: 100%;
  outline: transparent;
  border: none;
`;
const ModalLocation = styled(ModalSearch)`
  padding: 12px;
`;
const ContainerForText = styled.div``;
const ContainerRows = styled.div`
  max-height: 230px;
  box-sizing: border-box;
  overflow-y: auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 18px;
`;
const LocationRow = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
  padding: 0;
  text-align: left;
`;
export interface LocationButtonProps {
  onOpen: any;
  onClose: any;
  isOpen?: boolean;
  location?: string;
  count?: number;
  handleLocationChange?: any;
  locations: any[];
  className?: string;
  setSelectedPos: any;
}

export const LocationButton: FC<LocationButtonProps> = ({
  onOpen,
  onClose,
  isOpen,
  location,
  count,
  handleLocationChange,
  locations,
  setSelectedPos,
  ...props
}) => {
  const [openModal, setOpenModal] = useState(true);

  const theme = useTheme();
  const { secondary3, white } = theme.colors;
  const modalRef = useRef<any>(null);
  const buttonRef = useRef<any>(null);

  const handleClick = () => {
    setOpenModal(true);
  };
  const onClickOutsideHandler = useCallback(
    (event: any) => {
      if (
        openModal &&
        locations.length > 0 &&
        !(
          (buttonRef && buttonRef.current && buttonRef.current.contains(event.target)) ||
          (modalRef && modalRef.current && modalRef.current.contains(event.target))
        )
      ) {
        setOpenModal(false);
      }
    },
    [openModal, locations.length],
  );

  useEffect(() => {
    window.addEventListener('click', onClickOutsideHandler);
    return () => {
      window.removeEventListener('click', onClickOutsideHandler);
    };
  }, [onClickOutsideHandler]);

  const handleLocationClick = (location: any) => {
    setSelectedPos(location);
    setOpenModal(false);
  };
  const handleClose = () => {
    setSelectedPos(null);
    onClose();
  };

  return (
    <Button
      onClick={() => {
        onOpen();
      }}
      theme={theme}
      isOpen={isOpen}
      {...props}
    >
      <LocationImage />
      {isOpen ? (
        <>
          <LocationWrapper>
            <ContainerForText ref={buttonRef} onClick={handleClick}>
              {/*{!openModal ? (
                <Text type={TextType.TEXT_14_WHITE}>{location}</Text>
              ) : (*/}
              <InputLocation value={location} onChange={handleLocationChange} autoFocus={true} />
              {/*)}*/}
            </ContainerForText>
            <CloseButton color={white} hoverColor={secondary3} onClick={handleClose} />
          </LocationWrapper>
          {openModal && locations.length > 0 && (
            <ModalLocation ref={modalRef} theme={theme}>
              <ContainerRows>
                {locations.map((location: any) => (
                  <LocationRow onClick={() => handleLocationClick(location)}>{location.name}</LocationRow>
                ))}
              </ContainerRows>
            </ModalLocation>
          )}
        </>
      ) : null}
    </Button>
  );
};

LocationButton.defaultProps = {
  location: 'Brest',
};
