import React, { FC } from 'react';
// @ts-ignore
import styled from '@xstyled/styled-components';
import { Badges, BadgesType } from '../../badges/Badges';

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 18px;
  cursor: pointer;
`;

const Text = styled.p`
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-size: 10px;
  line-height: 15px;
  text-align: left;
  color: black2;
`;

interface PropsType {
  text: string;
  textBadges: string;
  type: BadgesType;
  onClick?: any;
}

export const ModalRow: FC<PropsType> = ({ text, textBadges, type, onClick }) => {
  return (
    <Container onClick={onClick}>
      <Text>{text}</Text>
      <Badges content={textBadges} type={type}></Badges>
    </Container>
  );
};
