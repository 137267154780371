import React, { FC } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';

import { CloseButtonRoundImage } from './components/CloseButtonRoundImage';

const Wrapper = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  cursor: pointer;
  & > svg > path {
    stroke: ${(props: any) => props.theme.colors.primary};
    transition: all 0.3s ease;
  }
  transition: all 0.3 ease;
  &:hover {
    opacity: 0.7;
    & > svg > path {
      stroke: ${(props: any) => (props.hoveredColor ? props.hoveredColor : props.theme.colors.primary)};
    }
  }
`;

export interface CloseButtonRoundProps {
  onClick: () => void;

  color?: string;
  hoveredColor?: string;
  className?: string;
}

export const CloseButtonRound: FC<CloseButtonRoundProps> = ({ onClick, color, hoveredColor, ...props }) => {
  const theme = useTheme();
  return (
    <Wrapper theme={theme} color={color} hoveredColor={hoveredColor} onClick={onClick} {...props}>
      <CloseButtonRoundImage />
    </Wrapper>
  );
};
