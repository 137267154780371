import React, { FC } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { LocationImage } from './components/LocationImage';
import { CloseButton } from '../close-button';

const Wrapper = styled.div`
  width: 327px;
  height: 40px;
  position: relative;
`;

const Input = styled.input`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: primary;
  border: none;
  border-radius: ${(props: any) => props.theme.borderRadius.primary};
  outline: none;
  padding: 6px 32px 6px 40px;
  font-family: 'Poppins', sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: white;
  ::placeholder {
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
    font-size: 14px;
    color: white;
    opacity: 0.6;
    letter-spacing: 0.2px;
  }
`;

const LocationImageWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 6px;
  left: 7px;
  & > svg {
    width: 28px;
    height: 28px;
  }
  & > svg > path {
    stroke: ${(props: any) => props.theme.colors.white};
  }
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
`;

export interface SearchInputProps {
  action: any;
  value: string;
  onChange: any;
  className?: string;
  languageStrings?: any;
}

export const SearchInput: FC<SearchInputProps> = ({ action, value, onChange, languageStrings, ...props }) => {
  const theme = useTheme();
  const { white } = theme.colors;
  return (
    <Wrapper {...props}>
      <LocationImageWrapper theme={theme}>
        <LocationImage />
      </LocationImageWrapper>
      <Input
        placeholder={
          languageStrings && languageStrings.insightMobilePlaceholder
            ? languageStrings.insightMobilePlaceholder
            : 'Choose a place...'
        }
        value={value}
        onChange={onChange}
        theme={theme}
      />
      <CloseButtonWrapper>
        <CloseButton onClick={action} color={white} hoverColor={white} />
      </CloseButtonWrapper>
    </Wrapper>
  );
};

SearchInput.defaultProps = {};
