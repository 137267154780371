import React from 'react';

export const EditImage = () => {
  return (
    <svg width="14" height="22" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.91663 1.74985C10.0698 1.59664 10.2517 1.47511 10.4519 1.39219C10.6521 1.30928 10.8666 1.2666 11.0833 1.2666C11.3 1.2666 11.5145 1.30928 11.7147 1.39219C11.9149 1.47511 12.0968 1.59664 12.25 1.74985C12.4032 1.90306 12.5247 2.08494 12.6076 2.28512C12.6905 2.4853 12.7332 2.69985 12.7332 2.91652C12.7332 3.13319 12.6905 3.34774 12.6076 3.54791C12.5247 3.74809 12.4032 3.92998 12.25 4.08318L4.37496 11.9582L1.16663 12.8332L2.04163 9.62485L9.91663 1.74985Z"
        stroke="#C7C9D9"
        stroke-width="1.16667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
